import axios from 'axios';
import { apiUrl } from "@/common/endpoint.const";
const instance = axios.create({
	baseURL: apiUrl,
	timeout: 1000 * 60 * 10,
})

instance.interceptors.request.use(
	config => {
		var token = sessionStorage.getItem('token');

		if (token)
			config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;

		if (sessionStorage.getItem("deviceId"))
			config.headers['athena-deviceid'] = sessionStorage.getItem('deviceId');

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

export default instance;