import { wsRelUrl } from "@/common/endpoint.const";
import { SEND_COMMAND, SERVER_COMMAND_ACK, SOCKET_SEND } from "@/store/actions.type";
import { sleep } from "@/common/utils";

class SocketioService {
	socket;
	actualID = null;
	occupiedID = [];
	occupiedUsers = [];
	arrIdDelete = [];
	listeners = {};

	constructor() { }

	async setupSocketConnection(port) {

		if (this.socket == undefined || this.socket.readyState !== WebSocket.OPEN) {

			this.socket = new WebSocket(`${wsRelUrl}?access_token=${sessionStorage.getItem("token")}`);

			this.socket.onopen = () => {
				console.log('Socket connected: ', wsRelUrl);
			};

			this.socket.onerror = (error) => {
				console.error('Connection error: ', error);
			};

			this.socket.onclose = (event) => {
				console.log('Socket disconnected: ', event.reason);
			};

			this.socket.onmessage = (event) => {
				const message = JSON.parse(event.data);
				console.log("Message received: ", message);

				this.emit(message.event, message.data);
			};

			await sleep(1000);

			if (this.socket.readyState !== WebSocket.OPEN) {
				console.error('Socket connection failed');
			}
		}
	}

	subscribe(event, callback) {
		if (!this.listeners[event]) {
			this.listeners[event] = [];
		}
		this.listeners[event].push(callback);
	}

	unsubscribe(event, callback) {
		if (!this.listeners[event]) return;
		this.listeners[event] = this.listeners[event].filter(listener => listener !== callback);
	}

	emit(event, data) {
		if (!this.listeners[event]) return;
		this.listeners[event].forEach(listener => listener(data));
	}

	elaborateData(data) {
		console.log("Dati ricevuti dal server: ", data);
		if (data != undefined && data != null) {
			this.occupiedID = data.arrID;
			this.occupiedUsers = data.arrUser;
		}
	}

	disconnect() {
		if (this.socket) {
			let jsonSend = {
				relevanceId: this.actualID,
				action: 'delete',
				user: JSON.parse(sessionStorage.getItem("user")).username
			}
			this.sendMessage(jsonSend);
			this.socket.close();
		}
	}

	clearData() {
		console.log("Pulizia dati in corso...")
		this.socket.send(JSON.stringify({ event: 'clear-data', data: "clean" }));
	}

	checkDataTranscriptionAndSynthesis() {
		console.log("Inserimento dati sintesi e trascrizione...")
		this.socket.send(JSON.stringify({ event: 'get-data-synthesis-transcrption', data: "get-data-synthesis-transcrption" }));
	}

	checkDataRelevance() {
		console.log("Inserimento dati sintesi e trascrizione...")
		this.socket.send(JSON.stringify({ event: 'get-data-relevance', data: "get-data-relevance" }));
	}

	savedDataRelevance() {
		this.socket.send(JSON.stringify({ event: 'save-data-relevance', data: "saved-data-relevance" }));
	}

	async sendMessage(value) {
		console.log('Invio messaggio ', value);
		if (value.action == 'insert') {
			this.actualID = value.relevanceId;
		}
		else {
			let indexOccupied = this.occupiedID.indexOf(this.actualID);
			this.occupiedUsers.splice(indexOccupied, 1);

			this.occupiedID.splice(indexOccupied, 1);
			this.actualID = null;
		}
		console.log("actualID: ", this.actualID);
		this.socket.send(JSON.stringify({ event: 'id-value', data: value }));
	}

	sendSynthesisOrTranscription(value, idRelevance, type) {
		let message = JSON.stringify({
			message: value,
			relevanceId: idRelevance,
			user: JSON.parse(sessionStorage.getItem("user")).username,
			type: type + " /savement/"
		});
		console.log("Invio del seguente messaggio: ", message);
		if (type === 'trascrizione') {
			this.socket.send(JSON.stringify({ event: 'transcription', data: message }));
		}
		else if (type === 'sintesi') {
			this.socket.send(JSON.stringify({ event: 'synthesis', data: message }));
		}
	}

	sendRelevanceNoteOrRelevanceGrade(value, idRelevance, type) {
		let message = JSON.stringify({
			message: value,
			relevanceId: idRelevance,
			user: JSON.parse(sessionStorage.getItem("user")).username,
			type: type
		});
		console.log("Invio del seguente messaggio: ", message);
		if (type === 'note') {
			this.socket.send(JSON.stringify({ event: 'set-note-relvance-grade', data: message }));
		}
		else if (type === 'grade') {
			this.socket.send(JSON.stringify({ event: 'set-relevance-grade', data: message }));
		}
	}
}

export default new SocketioService();
