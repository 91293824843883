<template>
	<div class="pa-4">
		<v-row>
			<v-col cols="8" v-if="rerender">
				<waveform v-if="isAudio(relevance.type)"
									:loading="loadingAudio"
									v-on:events="syncPoints"
									:points="relevance.events"
									ref="waveform"
									:file="relevance.file"
									v-on:set-duration="setDuration"
									v-on:peaks-ready="loadingStop"
									:relevance="relevance"
									:getEventDetails="getEventDetails">
				</waveform>
				<div v-if="relevance.type === 2">
					<v-banner>Acquisizione fotocamera del {{new Date(relevance.file.created_at).toLocaleString()}}</v-banner>
					<div class="d-flex justify-center align-center">
						<img id="relevanceImage" :src="apiUrl+'/downloadfile/'+relevance.file.name + '?access_token=' + this.token + '&deviceid=' + this.relevance.device.id" style="width:500px;margin-bottom:20px; margin-top:20px;" />
					</div>
				</div>
				<div v-if="relevance.type === 11">
					<pdf :src="apiUrl+'/downloadfile/'+relevance.file.name + '?access_token=' + this.token + '&deviceid=' + this.relevance.device.id"></pdf>
				</div>
				<div v-if="relevance.type === 12">
					<iframe :srcdoc="docxHTML" height="600px" width="100%"></iframe>
				</div>
				<div v-if="relevance.type === 1 || relevance.type === 13">
					<v-banner>File video del {{new Date(relevance.file.created_at).toLocaleString()}}</v-banner>
					<div class="d-flex justify-center align-center">
						<video controls autoplay preload="auto" controlsList="nodownload" disablePictureInPicture :style="{ maxWidth: '80%', maxHeight: '80%' }">
							<source :src="apiUrl+'/downloadfile/'+relevance.file.name + '?access_token=' + this.token + '&deviceid=' + this.relevance.device.id">
						</video>
					</div>
				</div>
				<div v-if="relevance.type === 14">
					<v-banner> Registrazione del {{ new Date(relevance.keylog.created_at).toLocaleString() }} </v-banner>
					<div style="margin: 10px;">
						App: {{ relevance.keylog.app }} <br>
						Testo: {{ relevance.keylog.text }} <br>
						Id: keylog-{{ relevance.keylog.id }} <br>
					</div>
				</div>
				<div v-if="relevance.type === 15">
					<v-banner> Registrazione del {{ new Date(relevance.created_at).toLocaleString() }} </v-banner>
					<div style="margin: 10px;">
						Latitudine: {{ relevance.position.lat }} <br>
						Longitudine: {{ relevance.position.lon }} <br>
						Id: gps-{{ relevance.position.id }} <br>
					</div>
				</div>
				<div>
					<v-list-item-subtitle v-if="socketArray.includes(relevance.id) && checkIfUserIsSame(relevance.id)">
						<span style="color:red">
							Traccia in ascolto da un altro operatore
						</span>
					</v-list-item-subtitle>
					<div class="container-text">
						<span class="text-legend title-legend">Legenda Tasti: </span>
						<span class="text-legend">F2 - Play/Pausa</span>
						<span class="text-legend">F3 - Indietro 3 sec</span>
						<span class="text-legend">F4 - Avanti 3 sec</span>
						<span class="text-legend">F9 - Rilevanza precedente</span>
						<span class="text-legend">F10 - Rilevanza successiva</span>
						<span class="text-legend">F12 - Seleziona non inerente</span>
					</div>
				</div>
				<div v-if="relevance.transcription">
					<t-text-area ref="textarea"
											 :transcription="relevance.transcription"
											 :saveRelevance="saveRelevance"
											 :saving="saving"
											 :relevance="relevance"
											 :exportRelevance="exportRelevance"
											 :exportPDF="exportPDF"
											 :exportWord="exportWord"
											 :exportWordLoad="exportWordLoad"
											 :synthesis="relevance.synthesis"
											 :relevanceId="relevance.id"
											 :changeSavedElementFunc="() => {changeSavedElement()}"
											 :editEventTT="editEvent"
											 :goToSecond="goToSecond"
											 :stopAudio="stopAudio"
											 :changeRelevance="changeRelevance"
											 :relevance_grade="relevance_grade"
											 :relevance_grades="relevance_grades"
											 :relevance_note="relevance_note"
											 :relevance_notes="relevance_notes"
											 :changeRelevanceNote="changeRelevanceNote">
					</t-text-area>

				</div>
			</v-col>

			<v-col cols="4" align-self="start">
				<div v-if="relevance">
					<relevance-details @focusOnEvent="eventFocused" :relevance="relevance" :duration="duration">
						<template v-slot:actions>


							<!-- <div class="container-element">
								<v-btn
									color="primary"
									block
									@click="saveRelevance()"
									:loading="saving"
									:disabled="socketArray.includes(relevance.id) && checkIfUserIsSame(relevance.id)"
									class="btn-export"
								>
									<v-icon>mdi-content-save</v-icon>Salva
								</v-btn>
								<v-btn
									color="green"
									block
									@click="exportRelevance()"
									:loading="exportPDF"
									class="btn-export"
								>
									<v-icon>mdi-export</v-icon>Esporta PDF
								</v-btn>
								<v-btn
									color="green"
									block
									@click="exportWord()"
									:loading="exportWordLoad"
									class="btn-export"
								>
									<v-icon>mdi-export</v-icon>Esporta Word
								</v-btn>
								<v-btn
									color="green"
									block
									@click="exportWord(true)"
									:loading="exportWordLoad"
									class="btn-export"
								>
									<v-icon>mdi-export</v-icon>Esporta Sintesi
								</v-btn>
							</div> -->
						</template>
					</relevance-details>
				</div>
				<v-card style="margin-top:10px;">
					<v-subheader class="border-bottom__divider">Tracce successive e precedenti</v-subheader>
					<v-card-title>
						<v-expansion-panels accordion>
							<v-expansion-panel>
								<v-expansion-panel-header>Filtri Generali</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-row dense>
										<v-col cols="12">
											<v-text-field v-model="relevanceProgPrecAndSuc"
																		type="number"
																		label="Inserisci il progressivo da filtrare">
											</v-text-field>
										</v-col>

										<v-col cols="12">
											<v-select v-model="relevanceGrade"
																:items="relevanceGrades"
																label="Filtra per rilevanza"
																return-object
																item-text="label"
																item-value="value"></v-select>
										</v-col>

										<v-col cols="12">
											<v-select v-model="relevanceNote"
																:items="relevanceNotes"
																label="Filtra per note"
																return-object
																item-text="label"
																item-value="value"></v-select>
										</v-col>
										<v-col cols="12">
											<v-select v-model="listenChoice"
																:items="listenChoices"
																label="Ascoltata"
																return-object
																item-text="label"
																item-value="value"></v-select>
										</v-col>
										<v-col cols="12">
											<v-select v-model="typeChoice"
																:items="typeChoices"
																label="Tipologia"
																return-object
																item-text="label"
																item-value="value"></v-select>
										</v-col>
									</v-row>
								</v-expansion-panel-content>
							</v-expansion-panel>
							<v-expansion-panel>
								<v-expansion-panel-header>Filtri per data</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-row dense>
										<v-col cols="12">
											<v-menu :close-on-content-click="false" :nudge-right="40" lazy
															transition="scale-transition" offset-y full-width min-width="290px">
												<template v-slot:activator="{ on }">
													<v-text-field v-model="filterDateFrom"
																				label="Dalla data"
																				prepend-icon="mdi-calendar"
																				readonly
																				v-on="on">
													</v-text-field>

												</template>
												<v-date-picker v-model="filterDateFrom" @input="menu = false"></v-date-picker>
											</v-menu>
										</v-col>
										<v-col cols="12">
											<v-menu :close-on-content-click="false" :nudge-right="40" lazy
															transition="scale-transition" offset-y full-width min-width="290px">
												<template v-slot:activator="{ on }">
													<v-text-field v-model="filterDateTo" label="Alla data" prepend-icon="mdi-calendar" readonly
																				v-on="on"></v-text-field>
												</template>
												<v-date-picker v-model="filterDateTo" @input="menu = false"></v-date-picker>
											</v-menu>
										</v-col>
										<v-col cols="12" style="display: flex;align-items: center;justify-content: center;">
											<div class="tooltip">
												<v-btn color="green" block @click="resetFilterData()">
													<v-icon style="font-size: 20px;">mdi-delete</v-icon>
												</v-btn>
												<span class="tooltiptext">Resetta i filtri date</span>
											</div>
										</v-col>
									</v-row>
								</v-expansion-panel-content>
							</v-expansion-panel>
							<v-expansion-panel>
								<v-expansion-panel-header>Filtri per testo trascrizione e sintesi</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-row dense>
										<v-col cols="12">
											<v-text-field v-model="filterTranscription"
																		label="Inserisci la trascrizione da filtrare">
											</v-text-field>
										</v-col>
										<v-col cols="12">
											<v-text-field v-model="filterSynthesis"
																		label="Inserisci la sintesi da filtrare">
											</v-text-field>
										</v-col>
									</v-row>
								</v-expansion-panel-content>
							</v-expansion-panel>
							<v-expansion-panel ref="trackList">
								<v-expansion-panel-header>Lista tracce</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-card-text>
										<v-list three-line>
											<v-list-item>Tracce Successive</v-list-item>
											<v-list-item v-if="next" v-for="next in nextRelevance" :key="next && next.id ? next.id+'succ' : ''">
												<v-list-item-avatar>
													<v-avatar :color="next.type | relevanceTypeIconColor">
														<v-icon dark>{{ next.type | relevanceTypeIcon }}</v-icon>
													</v-avatar>
												</v-list-item-avatar>

												<v-list-item-content>
													<v-list-item-title>
														{{ next.type | relevanceTypeLabel }} del {{ new Date(next.created_at).toLocaleString()}}
														<v-spacer style="display: inline-block"></v-spacer>
														<v-icon style="display: inline-block" v-if="next.transcriptionId === null || next.transcriptionId.toString().includes('$uid')">mdi-check</v-icon>
														<v-icon style="display: inline-block" v-if="next.transcriptionId !== null && !next.transcriptionId.toString().includes('$uid')">mdi-check-all</v-icon>
														<br />
														<!-- {{relevance.type | relevanceTypeLabel}} del {{new Date(relevance.created_at).getTime()/ 1000}} -->
													</v-list-item-title>
													<v-list-item-subtitle>
														<span class="no-bold"
																	v-if="
                                    (next.transcriptionId !== null && !next.transcriptionId.toString().includes('$uid')) &&
                                    (next.transcription && next.transcription.user && next.transcription.user.username)">
															- creata da: {{ next.transcription.user.username }}
														</span>

													</v-list-item-subtitle>
													<v-list-item-subtitle>
														<span v-if="(next.transcriptionId !== null && !next.transcriptionId.toString().includes('$uid'))" class="no-bold">
															- Trascrizione: {{ next.transcription.text.toString().slice(0,50) }}...
														</span>
													</v-list-item-subtitle>
													<v-list-item-subtitle>
														<span v-if="(next.synthesisId !== null && !next.synthesisId.toString().includes('$uid'))" class="no-bold">
															- Sintesi: {{ next.synthesis.text.toString().slice(0,50) }}...
														</span>
													</v-list-item-subtitle>
													<v-list-item-subtitle>
														<span class="no-bold"> Progressivo: {{ next.id }}</span>
													</v-list-item-subtitle>
													<v-list-item-subtitle v-if="socketArray.includes(next.id)">
														<span style="color:red" class="no-bold">
															Traccia in ascolto da un altro operatore
														</span>
													</v-list-item-subtitle>
													<v-list-item-subtitle v-if="(next.relevance_grade != null && !next?.transcriptionId?.toString()?.includes('$uid'))" class="no-bold">
														Grado rilevanza:
														<v-chip style="color: black !important;"
																		:color="next.relevance_grade | relevanceGradeColor">
															{{
                                next.relevance_grade | relevanceGradeLabel
															}}
														</v-chip>
													</v-list-item-subtitle>

													<v-list-item-subtitle v-if="(next.synthesisId !== null && next.synthesis.relevance_grade != null && next.synthesisId !== null && !next.synthesisId.toString().includes('$uid'))">
														Note rilevanza:
														<v-chip style="color: black !important;"
																		:color="next.synthesis.relevance_grade | relevanceNoteColor">
															{{
                                next.synthesis.relevance_grade | relevanceNoteLabel
															}}
														</v-chip>
													</v-list-item-subtitle>
												</v-list-item-content>

												<v-list-item-action @click="checkTranscription(next)">
													<v-list-item-action-text>{{next.type | relevanceActionLabel}}</v-list-item-action-text>
													<v-icon>mdi-arrow-right-drop-circle</v-icon>
												</v-list-item-action>
											</v-list-item>

											<v-list-item>Tracce Precedenti</v-list-item>
											<v-list-item v-if="prev" v-for="prev in precRelevance" :key="prev && prev.id ? prev.id+'prev' : ''">
												<v-list-item-avatar>
													<v-avatar :color="prev.type | relevanceTypeIconColor">
														<v-icon dark>{{ prev.type | relevanceTypeIcon }}</v-icon>
													</v-avatar>
												</v-list-item-avatar>

												<v-list-item-content>
													<v-list-item-title>
														{{ prev.type | relevanceTypeLabel }} del {{ new Date(prev.created_at).toLocaleString()}}
														<v-spacer style="display: inline-block"></v-spacer>
														<v-icon style="display: inline-block" v-if="prev.transcriptionId === null || prev.transcriptionId.toString().includes('$uid')">mdi-check</v-icon>
														<v-icon style="display: inline-block" v-if="prev.transcriptionId !== null && !prev.transcriptionId.toString().includes('$uid')">mdi-check-all</v-icon>
														<br />
														<!-- {{relevance.type | relevanceTypeLabel}} del {{new Date(relevance.created_at).getTime()/ 1000}} -->
													</v-list-item-title>
													<v-list-item-subtitle>
														<span class="no-bold"
																	v-if="
                                    (prev.transcriptionId !== null && !prev.transcriptionId.toString().includes('$uid')) &&
                                    (prev.transcription && prev.transcription.user && prev.transcription.user.username)">
															- creata da: {{ prev.transcription.user.username }}
														</span>

													</v-list-item-subtitle>
													<v-list-item-subtitle>
														<span v-if="(prev.transcriptionId !== null && !prev.transcriptionId.toString().includes('$uid'))" class="no-bold">
															- Trascrizione: {{ prev.transcription.text.toString().slice(0,50) }}...
														</span>
													</v-list-item-subtitle>
													<v-list-item-subtitle>
														<span v-if="(prev.synthesisId !== null && !prev.synthesisId.toString().includes('$uid'))" class="no-bold">
															- Sintesi: {{ prev.synthesis.text.toString().slice(0,50) }}...
														</span>
													</v-list-item-subtitle>
													<v-list-item-subtitle>
														<span class="no-bold"> Progressivo: {{ prev.id }}</span>
													</v-list-item-subtitle>
													<v-list-item-subtitle v-if="socketArray.includes(prev.id)">
														<span style="color:red" class="no-bold">
															Traccia in ascolto da un altro operatore
														</span>
													</v-list-item-subtitle>
													<v-list-item-subtitle class="no-bold" v-if="(prev.transcriptionId !== null && prev.relevance_grade != null && prev.transcriptionId !== null && !prev.transcriptionId.toString().includes('$uid'))">
														Grado rilevanza:
														<v-chip class="no-bold"
																		style="color: black !important;"
																		:color="prev.relevance_grade | relevanceGradeColor">
															{{
                                prev.relevance_grade | relevanceGradeLabel
															}}
														</v-chip>
													</v-list-item-subtitle>

													<v-list-item-subtitle class="no-bold" v-if="(prev.synthesisId !== null && prev.synthesis.relevance_grade != null && prev.synthesisId !== null && !prev.synthesisId.toString().includes('$uid'))">
														Note rilevanza:
														<v-chip style="color: black !important;"
																		:color="prev.synthesis.relevance_grade | relevanceNoteColor">
															{{
                                prev.synthesis.relevance_grade | relevanceNoteLabel
															}}
														</v-chip>
													</v-list-item-subtitle>
												</v-list-item-content>

												<v-list-item-action @click="checkTranscription(prev)">
													<v-list-item-action-text>{{prev.type | relevanceActionLabel}}</v-list-item-action-text>
													<v-icon>mdi-arrow-right-drop-circle</v-icon>
												</v-list-item-action>
											</v-list-item>
										</v-list>
									</v-card-text>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-card-title>
				</v-card>
				<v-card style="margin-top: 10px;" v-if="lat && long">
					<v-subheader>Luogo in cui è stata registrata la traccia</v-subheader>
					<l-map v-if="lat && long"
								 :zoom="zoom"
								 :center="center"
								 :options="mapOptions"
								 style="height: 200px; width: 500px;"
								 @update:center="centerUpdate"
								 @update:zoom="zoomUpdate">
						<l-tile-layer :url="url" />
						<l-marker :lat-lng="withTooltip" :icon="customIcon">
							<l-tooltip :options="{ permanent: true, interactive: true }">
								<div>
									Ultima posizione
								</div>
							</l-tooltip>
						</l-marker>
					</l-map>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import pdf from 'vue-pdf'
	import Relevance from "@/store/models/relevance.model";
	import Waveform from "@/components/relevance/Waveform";
	import TTextArea from "@/components/relevance/transcription/TTextArea";
	import RelevanceDetails from "@/components/relevance/RelevanceDetails";
	import { apiUrl } from '@/common/endpoint.const';
	import Notification from "@/store/models/notification.model";
	import { jsPDF } from "jspdf";
	import autoTable from 'jspdf-autotable';
	import moment from 'moment';
	import SocketioService from '../../services/socketio.service';
	import SavedRelevance from '../../services/savedRelevance.service';
	import Swal from 'sweetalert2';
	import { saveAs } from "file-saver"
	import axios from "../../axios";
	import {
		Document,
		Packer,
		Paragraph,
		TextRun,
		ImageRun,
		AlignmentType,
		TableCell,
		TableRow,
		Table,
	} from "docx"
	var mammoth = require('mammoth');
	import { latLng } from "leaflet";
	import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";


	// sessionStorage.setItem('filterJSON','{"relevance":"","note":"","ascoltata":"","tipologia":"","dallaData":"","allaData":"","trascrizioneText":"","sintesiText":"","progressivo":""}');
	const pointToEvent = (point, tId) => {
		const ev = {};
		const d = (new Date).toISOString();
		ev.happens_at = point.time;
		ev.relevance_grade = 0;
		ev.created_at = d;
		ev.last_modified = d;
		ev.relevanceId = tId;
		return ev;
	};

	const secondToTime = (secondsFrom) => {
		var sec_num = parseInt(secondsFrom, 10); // don't forget the second param
		var hours = Math.floor(sec_num / 3600);
		var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
		var seconds = sec_num - (hours * 3600) - (minutes * 60);

		if (hours < 10) { hours = "0" + hours; }
		if (minutes < 10) { minutes = "0" + minutes; }
		if (seconds < 10) { seconds = "0" + seconds; }
		return hours + ':' + minutes + ':' + seconds;
	};

	const getDuration = (relevance, refs) => {
		if (isAudioRelevance(relevance.type)) {
			return secondToTime(refs.waveform.peaks.player.getDuration());
		}
		else if (relevance.type === 1) {
			return "0";
		}
		else if (relevance.type === 2) {
			return "0";
		}
	};

	const getRit = (config, deviceId) => {
		return config.rit.filter(d => d.device == deviceId)[0]?.value || "";
	};

	const isAudioRelevance = (type) => {
		return type === 0 || type === 3 || type === 4 || type === 5 || type === 9 || type === 8 || type === 10;
	}

	function imageHeight(url, maxWidth, maxHeight) {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => {
				const ratio = img.width / img.height;
				let height = maxWidth / ratio;
				if (height > maxHeight) {
					height = maxHeight;
				}
				resolve(height);
			};
			img.onerror = reject;
			img.src = url;
		});
	}

	export default {
		name: "RelevanceAnalyzer",

		async created() {
			SavedRelevance.setSavedRelevanceBool(false, "RELEVANCE 1");
			SavedRelevance.setSavedElement(this.saveRelevance);
			SocketioService.setupSocketConnection(window.config.port_socket);
			console.log('RelevanceAnalyzer created');
			SocketioService.checkDataRelevance();

			SocketioService.subscribe('broadcast-id-value', this.handleBroadcastIdValue);

			console.log("this.TTextArea: ", this.$refs);

			if (!this.keyListenerAdded) {
				window.addEventListener("keydown", this.handleHotkeys);
				this.keyListenerAdded = true;
			}
		},
		async beforeDestroy() {
			SocketioService.unsubscribe('broadcast-id-value', this.handleBroadcastIdValue);

			if (this.keyListenerAdded) {
				window.removeEventListener("keydown", this.handleHotkeys);
				this.keyListenerAdded = false;
			}
		},
		async mounted() {
			this.$nextTick(() => {
				// Accedi al pannello di espansione utilizzando $refs e apri il pannello
				this.$refs.trackList.toggle();
			});
			await this.initTranscription();
			await this.initSynthesis();
			await this.getRelevance();
			await this.getRelevancePrecAndSucc();
			this.getRelevanceNote();
			this.getRelevanceGrade();
		},
		beforeUnmount() {
			SocketioService.disconnect();
		},
		components: {
			Waveform,
			TTextArea,
			pdf,
			RelevanceDetails,
			LMap,
			LTileLayer,
			LMarker,
			LPopup,
			LTooltip,
		},
		data() {
			let parsefilterJSON = JSON.parse(sessionStorage.getItem('filterJSON'));
			return {
				keyListenerAdded: false,
				customIcon: L.icon({
					iconUrl: window.location.origin + '/img/marker.png',
					iconSize: [24, 38], // Dimensione dell'icona in pixel
					iconAnchor: [11, 35], // Punto dell'icona che corrisponde alla posizione del marker
					popupAnchor: [-3, -76] // Punto da cui verrà aperto il popup rispetto all'iconAnchor
				}),
				url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
				center: latLng(0, 0),
				mapOptions: {
					zoomSnap: 0.5
				},
				duration: 0,
				zoom: 18,
				apiUrl,
				first: true,
				rerender: true,
				loadingAudio: true,
				loadingPages: false,
				saving: false,
				exportPDF: false,
				exportWordLoad: false,
				lat: "",
				long: "",
				savedElement: false,
				precRelevance: [],
				nextRelevance: [],
				relevance: [],
				relevanceData: [],
				socketArray: SocketioService.occupiedID,
				token: sessionStorage.getItem('token'),
				filterJSONToSend: {
					filter_relevance: parsefilterJSON && parsefilterJSON?.relevance ? parsefilterJSON?.relevance.value : null,
					filter_note: parsefilterJSON && parsefilterJSON?.note ? parsefilterJSON?.note.value : null,
					filter_listeining: parsefilterJSON && parsefilterJSON?.ascoltata ? parsefilterJSON?.ascoltata.value : null,
					filter_type: parsefilterJSON && parsefilterJSON?.tipologia ? parsefilterJSON?.tipologia.value : null,
					filter_date_from: parsefilterJSON && parsefilterJSON?.dallaData ? parsefilterJSON?.dallaData : null,
					filter_date_to: parsefilterJSON && parsefilterJSON?.allaData ? parsefilterJSON?.allaData : null,
					filter_transcription: parsefilterJSON && parsefilterJSON?.trascrizioneText ? parsefilterJSON?.trascrizioneText : null,
					filter_synthesis: parsefilterJSON && parsefilterJSON?.sintesiText ? parsefilterJSON?.sintesiText : null,
					filter_prog: parsefilterJSON && parsefilterJSON?.progressivo ? parsefilterJSON?.progressivo : null
				},
				filterJSONQueryParams: {
					relevance: parsefilterJSON && parsefilterJSON?.relevance ? parsefilterJSON?.relevance : "",
					note: parsefilterJSON && parsefilterJSON?.note ? parsefilterJSON?.note : "",
					ascoltata: parsefilterJSON && parsefilterJSON?.ascoltata ? parsefilterJSON?.ascoltata : "",
					tipologia: parsefilterJSON && parsefilterJSON?.tipologia ? parsefilterJSON?.tipologia : "",
					dallaData: parsefilterJSON && parsefilterJSON?.dallaData ? parsefilterJSON?.dallaData : "",
					allaData: parsefilterJSON && parsefilterJSON?.allaData ? parsefilterJSON?.allaData : "",
					trascrizioneText: parsefilterJSON && parsefilterJSON?.trascrizioneText ? parsefilterJSON?.trascrizioneText : "",
					sintesiText: parsefilterJSON && parsefilterJSON?.sintesiText ? parsefilterJSON?.sintesiText : "",
					progressivo: parsefilterJSON && parsefilterJSON?.progressivo ? parsefilterJSON?.progressivo : ""
				},
				relevance_grades: [
					{
						label: 'Molto importante',
						value: 5
					},
					{
						label: 'Utile ai fini dell\'indagine',
						value: 4
					},
					{
						label: 'Da valutare',
						value: 3
					},
					{
						label: 'Non inerente',
						value: 2
					},
					{
						label: 'Non utilizzabile',
						value: 1
					},
					{
						label: 'Non impostata',
						value: 0
					}
				],
				relevance_notes: [
					{
						label: 'Non impostata',
						value: 0
					},
					{
						label: 'Da trascrivere',
						value: 1
					},
					{
						label: 'Trascritta',
						value: 2
					},
					{
						label: 'Da tradurre',
						value: 3
					},
					{
						label: 'Valutare Traduzione',
						value: 4
					},
					{
						label: 'Info utile',
						value: 5
					},
					{
						label: 'Positiva',
						value: 6
					},
					{
						label: 'Rilevante',
						value: 7
					},
					{
						label: 'Attinente droga',
						value: 8
					},
					{
						label: 'Da riascoltare',
						value: 9
					},
					{
						label: 'Tutte',
						value: null
					},
					{
						label: 'Non impostata',
						value: null
					}
				],
				relevance_note: {},
				relevanceGrade: parsefilterJSON && parsefilterJSON?.relevance ? parsefilterJSON?.relevance : {
					label: 'Tutte',
					value: null
				},
				relevance_grade: {},
				filterData: null,
				relevanceGrades: [
					{
						label: 'Molto importante',
						value: 5
					},
					{
						label: 'Utile ai fini dell\'indagine',
						value: 4
					},
					{
						label: 'Da valutare',
						value: 3
					},
					{
						label: 'Non inerente',
						value: 2
					},
					{
						label: 'Non utilizzabile',
						value: 1
					},
					{
						label: 'Non impostata',
						value: 0
					},
					{
						label: 'Tutte',
						value: null
					}
				],
				listenChoice: parsefilterJSON && parsefilterJSON?.ascoltata ? parsefilterJSON?.ascoltata : window.relevancePage.listenChoice,
				typeChoice: parsefilterJSON && parsefilterJSON?.tipologia ? parsefilterJSON?.tipologia : window.relevancePage.typeChoice,
				listenChoices: [
					{ label: 'Si', value: true },
					{ label: 'No', value: false },
					{ label: 'Tutte', value: null }
				],
				relevanceNotes: [
					{
						label: 'Non impostato',
						value: 0
					},
					{
						label: 'Da trascrivere',
						value: 1
					},
					{
						label: 'Trascritta',
						value: 2
					},
					{
						label: 'Da tradurre',
						value: 3
					},
					{
						label: 'Valutare Traduzione',
						value: 4
					},
					{
						label: 'Info utile',
						value: 5
					},
					{
						label: 'Positiva',
						value: 6
					},
					{
						label: 'Rilevante',
						value: 7
					},
					{
						label: 'Attinente droga',
						value: 8
					},
					{
						label: 'Da riascoltare',
						value: 9
					},
					{
						label: 'Tutte',
						value: null
					}
				],
				relevanceNote: parsefilterJSON && parsefilterJSON.note ? parsefilterJSON?.note : {
					label: 'Tutte',
					value: null
				},
				filterTranscription: parsefilterJSON && parsefilterJSON?.trascrizioneText ? parsefilterJSON?.trascrizioneText : "",
				filterSynthesis: parsefilterJSON && parsefilterJSON?.sintesiText ? parsefilterJSON?.sintesiText : "",
				filterDateFrom: parsefilterJSON && parsefilterJSON?.dallaData ? parsefilterJSON?.dallaData : window.relevancePage.dateFrom,
				filterDateTo: parsefilterJSON && parsefilterJSON?.allaData ? parsefilterJSON?.allaData : window.relevancePage.dateTo,
				filterProgNumber: null,
				relevanceProgPrecAndSuc: null,
				typeChoices: [
					{ label: 'File audio', value: 0 },
					{ label: 'File video', value: 1 },
					{ label: 'File immagine', value: 2 },
					{ label: 'Registrazione ambientale', value: 3 },
					{ label: 'Audio chiamata Whatsapp', value: 4 },
					{ label: 'Audio chiamata Telegram', value: 5 },
					{ label: 'Audio chiamata Instagram', value: 10 },
					{ label: 'Documenti', value: "documents-filter" },
					{ label: 'Keylogger', value: 14 },
					{ label: 'Posizione', value: 15 },
					{ label: 'Tutte', value: null }
				],
				docxHTML: "",
			}
		},
		computed: {
			withTooltip() {
				if (this.lat && this.long) {
					this.center = latLng(this.lat, this.long);
					return latLng(this.lat, this.long)
				}
				return latLng(0, 0);
			},
			user() {
				return this.$store.getters.currentUser;
			},
		},
		methods: {

			handleBroadcastIdValue(data) {
				SocketioService.elaborateData(data);
				this.socketArray = SocketioService.occupiedID;
			},

			handleHotkeys(e) {
				if (e != undefined) {
					console.log("e.key: ", e.key);
					if (e?.key == "F2") {
						e.preventDefault();
						if (isAudioRelevance(this.relevance.type))
							if (this.$refs.waveform.isPlaying)
								this.$refs.waveform.pause();
							else {
								this.$refs.waveform.back(1);
								this.$refs.waveform.play();
							}
					}
					else if (e?.key == "F3") {
						e.preventDefault();
						this.$refs.waveform.back(3);
					}
					else if (e?.key == "F4") {
						e.preventDefault();
						this.$refs.waveform.forward(3);
					}
					else if (e?.key == "F9") {
						e.preventDefault();
						this.goToPrevTranscription();
					}
					else if (e?.key == "F10") {
						e.preventDefault();
						this.goToNextTranscription();
					}
					else if (e?.key == "F12") {
						e.preventDefault();
						this.saveRelevance(true);
						this.goToNextTranscription();
					}
				}
			},
			centerUpdate(center) {
				this.currentCenter = center;
			},
			centerMapOnPosition(position) {
				this.center = latLng(position.lat, position.lon);
			},
			zoomUpdate(zoom) {
				this.currentZoom = zoom;
			},
			getEventDetails() {
				console.log("EVENT SAVA GET EVENT");
				this.$refs.textarea.getEventDetails()
			},
			stopAudio() {
				if (this.$refs.waveform) this.$refs.waveform.stopAudioForSecond();
			},
			resetFilterData() {
				this.filterDateFrom = null;
				this.filterDateTo = null;
			},
			getTypeOfFileByValue(element) {
				if (element?.file?.name) {
					let fileName = element?.file?.name;
					console.log("FILENAME: ", fileName);
					if (fileName) {
						if (fileName.includes("IMG") && fileName.includes("WA")) {
							return "Immagine WhatsApp";
						}
						if (fileName.includes("PTT") && fileName.includes("WA") && fileName.includes(".opus")) {
							return "Vocale WhatsApp";
						}
					}
				}
			},
			async goToSecond(second) {
				this.$refs.waveform.goToSecond(second);
			},
			async changeSavedElement() {
				SavedRelevance.setSavedRelevanceBool(true);
				this.initSynthesisAndTranscription();
				this.savedElement = true;
				console.log("this.relevance: ", this.relevance);
				SavedRelevance.setRelevance(this.relevance);
			},
			async convertDocxToPdfFromUrl() {
				let res = await fetch(`${apiUrl}/downloadfile/${this.relevance.file.name}`);
				// convert res to buffer
				res.arrayBuffer().then(async (arrayBuffer) => {
					//convert array buffer to buffer
					const buffer = Buffer.from(arrayBuffer);
					const html = await mammoth.convertToHtml({ arrayBuffer: buffer });
					// insert html into iframe
					this.docxHTML = "<html><head><style>body{background-color:white;}</style></head><body>" + html.value + "</body></html>";
					console.log(this.docxHTML);
				});
				// convert buffer to pdf

			},
			async getRelevancePrecAndSucc() {
				console.log(this.filterJSONToSend);
				const result = await Relevance.api().post(`${apiUrl}/device/relevance-prec-succ/${this.relevance.id}`,
					this.filterJSONToSend
				);

				const data = result?.response?.data;
				this.precRelevance = data ? [data.prec] : [];
				this.nextRelevance = data ? [data.succ] : [];
			},
			convertImageToBase64(path) {
				return new Promise((resolve, reject) => {
					const img = new Image();
					img.crossOrigin = 'Anonymous';
					img.onload = () => {
						const canvas = document.createElement('CANVAS');
						const ctx = canvas.getContext('2d');
						let dataURL;
						canvas.height = img.height;
						canvas.width = img.width;
						ctx.drawImage(img, 0, 0);
						dataURL = canvas.toDataURL('image/png');
						resolve(dataURL);
						canvas = null;
					};
					img.src = path;
				});
			},
			editEvent(eventDetails) {
				console.log("eventDetails: ", eventDetails);
				console.log("this.refs: ", this.$refs);
				this.$refs.waveform.openDialog({ ...eventDetails });
			},
			getRelevanceNote() {
				if (this.relevance?.synthesis?.relevance_grade != null && this.relevance?.synthesis?.relevance_grade != undefined) {
					const relevance_note = this.relevance?.synthesis?.relevance_grade

					if (relevance_note == 0) {
						this.relevance_note = {
							label: 'Non impostata',
							value: 0
						};
					}
					else if (relevance_note == 1) {
						this.relevance_note = {
							label: 'Da trascrivere',
							value: 1
						};
					}
					else if (relevance_note == 2) {
						this.relevance_note = {
							label: 'Trascritta',
							value: 2
						}
					}
					else if (relevance_note == 3) {
						this.relevance_note = {
							label: 'Da tradurre',
							value: 3
						}
					}
					else if (relevance_note == 4) {
						this.relevance_note = {
							label: 'Valutare Traduzione',
							value: 4
						}
					}
					else if (relevance_note == 5) {
						this.relevance_note = {
							label: 'Info utile',
							value: 5
						}
					}
					else if (relevance_note == 6) {
						this.relevance_note = {
							label: 'Positiva',
							value: 6
						}
					}
					else if (relevance_note == 7) {
						this.relevance_note = {
							label: 'Rilevante',
							value: 7
						}
					}
					else if (relevance_note == 8) {
						this.relevance_note = {
							label: 'Attinente droga',
							value: 8
						}
					}
					else if (relevance_note == 9) {
						this.relevance_note = {
							label: 'Da riascoltare',
							value: 9
						}
					}
				}
			},
			getRelevanceGrade() {
				if (this.relevance?.relevance_grade != null && this.relevance?.relevance_grade != undefined) {
					if (this.relevance?.relevance_grade == 0) {
						this.relevance_grade = {
							label: 'Non impostata',
							value: 0
						}
					}
					else if (this.relevance?.relevance_grade == 1) {
						this.relevance_grade = {
							label: 'Non utilizzabile',
							value: 1
						}
					}
					else if (this.relevance?.relevance_grade == 2) {
						this.relevance_grade = {
							label: 'Non inerente',
							value: 2
						}
					}
					else if (this.relevance?.relevance_grade == 3) {
						this.relevance_grade = {
							label: 'Da valutare',
							value: 3
						}
					}
					else if (this.relevance?.relevance_grade == 4) {
						this.relevance_grade = {
							label: 'Utile ai fini dell\'indagine',
							value: 4
						}
					}
					else if (this.relevance?.relevance_grade == 5) {
						this.relevance_grade = {
							label: 'Molto importante',
							value: 5
						}
					}
				}
			},
			getUserOccupied(idRelevance) {
				let arrID = [];
				let users = [];
				for (let i = 0; i < this.socketArray.length; i++) {
					if (this.socketArray[i] == idRelevance) {
						arrID.push(i);
					}
				}
				for (let i = 0; i < arrID.length; i++) {
					users.push(SocketioService.occupiedUsers[arrID[i]]);
				}
				return users;
			},
			checkIfUserIsSame(idRelevance) {
				let users = this.getUserOccupied(idRelevance);
				console.log("getUserOccupied: ", users);
				console.log("ID RELEVANCE: ", idRelevance);
				let localUser = JSON.parse(sessionStorage.getItem("user")).username;
				return users[0] != localUser;
			},
			async changeRelevance(a) {
				console.log("RELEVANCE GRADE: ", a);
				console.log("relevance_grade: ", this.relevance_grade);
				let relevanceGradeLabel;
				this.relevance_grades.forEach((grade) => {
					if (grade.value == a) {
						relevanceGradeLabel = grade.label;
					}
				});
				this.relevance_grade = {
					label: relevanceGradeLabel,
					value: a
				}
				console.log("relevance_grade: ", this.relevance_grade);
				SavedRelevance.setSavedRelevanceBool(true);
				this.initSynthesisAndTranscription();
				this.savedElement = true;
				SavedRelevance.setRelevance(this.relevance);
				this.relevance.relevance_grade = a;
				// SocketioService.sendRelevanceNoteOrRelevanceGrade(a,this.$route.params.relevanceId,"grade");
				await Relevance.update({
					data: {
						id: this.relevance.id,
						relevance_grade: a,
						saved: false
					}
				})
			},
			async changeRelevanceNote(a) {
				console.log("NOTE DI RILEVANZA: ", a);
				console.log("relevance_note: ", this.relevance_note);
				let relevanceNoteLabel;
				this.relevance_notes.forEach((note) => {
					if (note.value == a) {
						relevanceNoteLabel = note.label;
					}
				});
				this.relevance_note = {
					label: relevanceNoteLabel,
					value: a
				}
				SavedRelevance.setSavedRelevanceBool(true);
				this.initSynthesisAndTranscription();
				this.savedElement = true;
				SavedRelevance.setRelevance(this.relevance);
				this.relevance.synthesis.relevance_grade = a;
				// SocketioService.sendRelevanceNoteOrRelevanceGrade(a,this.$route.params.relevanceId,"note");
				await Relevance.update({
					data: {
						id: this.relevance.id,
						synthesis: {
							id: this.relevance.synthesis.id,
							relevance_grade: a
						},
						saved: false
					}
				})
			},
			async syncPoints(points) {
				const result = await Relevance.update({
					data: {
						id: this.relevance.id,
						events: [pointToEvent(points, this.relevance.id)],
					},
					insert: ['relevanceEvents']
				})
			},
			loadingStop() {
				this.loadingAudio = false;
			},
			async checkTranscription(relevance) {
				console.log("this.savedElement: ", this.savedElement);
				if (!this.socketArray.includes(relevance.id) && this.checkIfUserIsSame(relevance.id)) {
					if (this.savedElement) {
						Swal.fire({
							title: 'Sei sicuro di voler cambiare acquisizione?',
							text: "hai effettuato una modifica ma non hai salvato!",
							icon: 'warning',
							showCancelButton: true,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							allowOutsideClick: false,
							allowEscapeKey: false,
							confirmButtonText: 'Salva e cambia acquisizione!',
							cancelButtonText: 'Non salvare e cambia acquisizione!',
						}).then(async (result) => {
							console.log("result.isConfirmed: ", result.isConfirmed);
							if (result.isConfirmed) {
								await this.saveRelevance()
							}
							this.goToTranscription(relevance);
						});
					}
					else {
						this.goToTranscription(relevance);
					}
				}
				else {
					this.goToTranscription(relevance);
				}
			},
			async goToTranscription(relevance) {

				if (this.relevanceNote != null && this.relevanceNote.value != null) {
					this.filterJSONQueryParams.note = JSON.parse(JSON.stringify(this.relevanceNote));
				}
				sessionStorage.setItem('filterJSON', JSON.stringify(this.filterJSONQueryParams));

				console.log("Rimozione del seguente ID: " + this.relevance.id);
				let jsonSendDelete = {
					relevanceId: this.relevance.id,
					action: 'delete',
					user: JSON.parse(sessionStorage.getItem("user")).username
				}
				console.log("jsonSendDelete: ", jsonSendDelete)
				await SocketioService.sendMessage(jsonSendDelete);

				let jsonSendInsert = {
					relevanceId: relevance.id,
					action: 'insert',
					user: JSON.parse(sessionStorage.getItem("user")).username
				}
				SocketioService.sendMessage(jsonSendInsert);

				const routeData = {
					path: "/dashboard",
					query: this.$route.query,
					params: this.$route.params
				}

				this.$router.push(routeData).then(() => {

					const newUrl = `/device/relevance/${relevance.id}`;

					const routeData = {
						path: newUrl,
						query: this.$route.query,
						params: this.$route.params
					}
					this.$router.push(routeData);
				});
				this.rerender = false;
				this.$nextTick(() => this.rerender = true);
			},
			goToNextTranscription() {
				const next = this.nextRelevance.filter(d => d.id == (this.relevance.id + 1))[0];
				this.goToTranscription(next);
			},
			goToPrevTranscription() {
				const prev = this.precRelevance.filter(d => d.id == (this.relevance.id - 1))[0];
				this.goToTranscription(prev);
			},
			isAudio(type) {
				return isAudioRelevance(type);
			},

			rotateImage(fileName, degrees) {
				return new Promise((resolve, reject) => {
					const img = new Image();
					img.src = fileName;
					img.crossOrigin = "anonymous";
					img.onload = () => {
						const canvas = document.createElement('canvas');
						const ctx = canvas.getContext('2d');
						canvas.width = img.width;
						canvas.height = img.height;
						ctx.translate(canvas.width / 2, canvas.height / 2);
						ctx.rotate(this.degreesToRadians(degrees));
						ctx.drawImage(img, -img.width / 2, -img.height / 2);
						resolve(canvas.toDataURL());
					};
				});
			},
			degreesToRadians(degrees) {
				return degrees * (Math.PI / 180);
			},
			async exportRelevance() {
				try {
					this.exportPDF = true;
					const doc = new jsPDF();
					doc.addImage(window.config.reportImageLogo, "JPEG", 95, 10, 20, 20);
					doc.setFont("times", "bold");
					doc.setFontSize(28);
					doc.text(window.config.legione, 105, 40, null, null, "center");
					doc.setFont("times", "normal");
					doc.setFontSize(20);
					doc.text(window.config.comando, 105, 50, null, null, "center");
					doc.setFontSize(18);
					doc.text(window.config.nucleo, 105, 60, null, null, "center");
					doc.setFontSize(15);
					const text = window.config.oggetto;
					const width = doc.internal.pageSize.getWidth() - 40; // larghezza massima disponibile per il testo
					const lines = doc.splitTextToSize(text, width);
					doc.setFontSize(15);
					doc.text(20, 70, lines);
					doc.addImage(window.config.reportImageDivider, "JPEG", 80, 95, 50, 10);
					let actualY = 0;
					autoTable(
						doc,
						{
							startY: 110,
							tableLineColor: [0, 0, 0],
							tableLineWidth: 0.1,
							styles: {
								lineColor: [0, 0, 0],
								lineWidth: 0.1,
							},
							headStyles: { textColor: [0, 0, 0], halign: 'center', fillColor: [210, 210, 210] }, head: [['Progr.', 'Data e ora', 'Durata', 'Bersaglio', 'P.P - RIT"']],
							theme: 'grid',
							body: [[
								{ content: this.relevance.id, styles: { halign: 'center', fillColor: [255, 255, 255] } },
								{ content: moment(this.relevance.file.created_at).format('DD/MM/YYYY HH:mm'), styles: { halign: 'center', fillColor: [255, 255, 255] } },
								{ content: getDuration(this.relevance, this.$refs), styles: { halign: 'center', fillColor: [255, 255, 255] } },
								{ content: 'IMEI:' + this.relevance.device.imei1, styles: { halign: 'center', fillColor: [255, 255, 255] } },
								{ content: getRit(window.config, this.relevance.device.id), styles: { halign: 'center', fillColor: [255, 255, 255] } }
							],
							],
						}
					);
					actualY = doc.lastAutoTable.finalY;
					const arrayRelevanceType = [1, 2];
					if (this.relevance?.transcription?.user?.username) {
						doc.autoTable({
							startY: actualY,
							columnStyles: {
								0: {
									cellWidth: 40
								}
							},
							theme: 'grid',
							bodyStyles: {
								lineColor: [0, 0, 0]
							},
							body: [
								[
									{
										content: 'Esportato il: ' + moment().format('DD/MM/YYYY HH:mm:ss'),
										styles: {
											fillColor: [210, 210, 210],
											textColor: [0, 0, 0]
										}
									},
									{
										content: ' Operatore di PG: ' + this.relevance.transcription.user.username,
										styles: {
											fillColor: [255, 255, 255],
											textColor: [0, 0, 0]
										}
									}
								]
							],
						});
						actualY = doc.lastAutoTable.finalY;
					}
					autoTable(
						doc,
						{
							startY:
								doc.lastAutoTable.finalY, columnStyles: {
									0: { cellWidth: 40 }
								},
							theme: 'grid',
							bodyStyles: { lineColor: [0, 0, 0] },
							body: [
								[
									{ content: 'Interlocutori', styles: { fillColor: [210, 210, 210], textColor: [0, 0, 0] } },
									{ content: this.relevance.transcription.interlocutors ? this.relevance.transcription.interlocutors.join() : "", styles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] } }
								]
							],
						}
					);
					actualY = doc.lastAutoTable.finalY;
					if (this.relevance.type == 2) {
						const maxWidth = 110;
						const fileName = apiUrl + '/downloadfile/' + this.relevance.file.name + '?access_token=' + sessionStorage.getItem('token');
						const x = 30;
						const y = doc.lastAutoTable.finalY + 10;
						const maxHeight = doc.internal.pageSize.getHeight() - y; // Calcola l'altezza massima dell'immagine

						const height = await imageHeight(fileName, maxWidth, maxHeight); // Calcola l'altezza dell'immagine
						actualY += height;
						console.log("fileName: ", fileName);
						doc.addImage(this.nameFile, "JPEG", x, 0, maxWidth, height, "", "NONE", 270);
					}
					autoTable(doc, {
						startY: actualY + 20, theme: 'grid', bodyStyles: { lineColor: [0, 0, 0] }, body: [
							[{ content: this.relevance.transcription.text, styles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] } }]
						]
					});
					actualY = doc.lastAutoTable.finalY;
					doc.setFontSize(10);
					doc.text(105, actualY + 35, "L'UFFICIALE DI P.G.", null, null, "center");
					doc.text(105, actualY + 45, "_______________________", null, null, "center");
					doc.save(this.relevance.id + ".pdf");
				}
				catch (error) {
					console.log(error);
				}
				finally {
					this.exportPDF = false;
				}
			},
			// create a function for get day month and years from public server api

			//create a function to get hours and minutes from public server api

			escapeRegExp(string) {
				return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
			},
			async exportWord(synthesisPrint = false) {
				try {
					let today = new Date();
					let dd = today.getDate();
					let mm = today.getMonth() + 1;
					let yyyy = today.getFullYear();
					let hh = today.getHours();
					let min = today.getMinutes();

					let relevanceDate = new Date(this.relevance.created_at).toLocaleString();

					this.exportWordLoad = true;
					let transcriptionParagraph = new Paragraph({ children: [] });
					let ImageParagraph = new Paragraph({ children: [] });
					let keylogTable = new TableRow({ children: [] });
					let positionTable = new TableRow({ children: [] });
					var exportDocumentInfos = (await axios.get(`${apiUrl}/device/get-document-export`)).data;

					let RIT = "";
					let oggettoTable = new TableRow({ children: [] });
					let oggetto = exportDocumentInfos.oggetto;
					if (oggetto.length > 0) {
						oggetto = oggetto
							.replace(new RegExp(this.escapeRegExp('{{progressivo}}'), 'g'), this.relevance.id);
						oggettoTable = new TableRow({
							children: [
								new TableCell({
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: "Oggetto",
													bold: true,
													color: '000000',
													size: 24.5,
												}),
											]
										}),
									]
								}),
								new TableCell({
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: oggetto,
													color: '000000',
													size: 24.5,
												}),
											]
										}),
									]
								}),
							]
						})
					}

					let info_modulo = exportDocumentInfos.info_modulo
						.replace(new RegExp(this.escapeRegExp('{{anno}}'), 'g'), yyyy)
						.replace(new RegExp(this.escapeRegExp('{{mese}}'), 'g'), mm)
						.replace(new RegExp(this.escapeRegExp('{{giorno}}'), 'g'), dd)
						.replace(new RegExp(this.escapeRegExp('{{orario}}'), 'g'), hh + ":" + min)
						.replace(new RegExp(this.escapeRegExp('{{firmatario}}'), 'g'), exportDocumentInfos.firmatario);
					if (this.relevance.type == 3) {
						info_modulo = info_modulo.replace(new RegExp(this.escapeRegExp('{{RIT}}'), 'g'), exportDocumentInfos.rit_acquisizioni_ambientali);
						RIT = exportDocumentInfos.info_proc.replace(new RegExp(this.escapeRegExp('{{RIT}}'), 'g'), exportDocumentInfos.rit_acquisizioni_ambientali);
					}
					else {
						info_modulo = info_modulo.replace(new RegExp(this.escapeRegExp('{{RIT}}'), 'g'), exportDocumentInfos.rit_acquisizioni);
						RIT = exportDocumentInfos.info_proc.replace(new RegExp(this.escapeRegExp('{{RIT}}'), 'g'), exportDocumentInfos.rit_acquisizioni);
					}
					if (!synthesisPrint) {
						if (this.relevance.transcription && this.relevance.transcription.text) {
							transcriptionParagraph = new Paragraph({
								children: [
									// insert transcription text
									new TextRun({
										text: "Trascrizione: " + this.relevance.transcription.text,
										size: 24.5,
										font: {
											name: 'Calibri',
											size: 24.5,
										},
									}),
								]
							})
						}
					}
					else {
						if (this.relevance.synthesis && this.relevance.synthesis.text) {
							transcriptionParagraph = new Paragraph({
								children: [
									// insert synthesis text
									new TextRun({
										text: "Sintesi: " + this.relevance.synthesis.text,
										size: 24.5,
										font: {
											name: 'Calibri',
											size: 24.5,
										},
									}),
								]
							})
						}
					}
					if (this.relevance.type == 2) {
						let fileName = apiUrl + '/downloadfile/' + this.relevance.file.name + '?access_token=' + sessionStorage.getItem('token');
						const degrees = 0; // Numero di gradi di rotazione desiderati
						const rotatedImageData = await this.rotateImage(fileName, degrees);
						const image = new ImageRun({
							data: rotatedImageData,
							transformation: {
								width: 300,
								height: 300,
							},
							alignment: AlignmentType.CENTER,
						});
						ImageParagraph = new Paragraph({
							alignment: AlignmentType.CENTER,
							children: [
								image,
							],
						});
					}
					if (this.relevance.type == 14) {
						/// TODO: Modificare i blocchi di docx creando funzioni per uniformare il tutto
						// Esportazione keylog
						const keylog = this.relevance.keylog;
						const keylogId = keylog.id;
						const app = keylog.app;
						const text = keylog.text;
						const createdAt = keylog.created_at;

						const tableCellMargin = { left: 100, right: 100 };

						const keylogTableHeader = new TableRow({
							children: [
								new TableCell({
									margins: tableCellMargin,
									shading: {
										fill: 'D9D9D9', // Colore di riempimento grigio
									},
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: "Id",
													bold: true,
													color: '000000',
													size: 24.5,
												})
											]
										})
									]
								}),
								new TableCell({
									margins: tableCellMargin,
									shading: {
										fill: 'D9D9D9', // Colore di riempimento grigio
									},
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: "App",
													bold: true,
													color: '000000',
													size: 24.5,
												}),
											]
										}),
									]
								}),
								new TableCell({
									margins: tableCellMargin,
									shading: {
										fill: 'D9D9D9', // Colore di riempimento grigio
									},
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: "Testo",
													bold: true,
													color: '000000',
													size: 24.5,
												})
											]
										})
									]
								}),
								new TableCell({
									margins: tableCellMargin,
									shading: {
										fill: 'D9D9D9', // Colore di riempimento grigio
									},
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: "Data di creazione",
													bold: true,
													color: '000000',
													size: 24.5,
												})
											]
										})
									]
								}),
							]
						});

						const keylogRow = new TableRow({
							children: [
								new TableCell({
									margins: tableCellMargin,
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: keylogId.toString(),
													bold: true,
													color: '000000',
													size: 24.5,
												}),
											]
										}),
									]
								}),
								new TableCell({
									margins: tableCellMargin,
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: app,
													color: '000000',
													size: 24.5,
												}),
											]
										}),
									]
								}),
								new TableCell({
									margins: tableCellMargin,
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: text,
													color: '000000',
													size: 24.5,
												}),
											]
										}),
									]
								}),
								new TableCell({
									margins: tableCellMargin,
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: new Date(createdAt).toLocaleString(),
													color: '000000',
													size: 24.5,
												}),
											]
										}),
									]
								}),
							]
						});

						keylogTable = new Table({
							alignment: AlignmentType.CENTER,
							columnWidths: [2250, 2250, 2250, 2250],
							rows: [
								keylogTableHeader,
								keylogRow
							],
						});
					}
					if (this.relevance.type == 15) {
						/// TODO: Modificare i blocchi di docx creando funzioni per uniformare il tutto
						// Esportazione posizione
						const position = this.relevance.position;
						const positionId = position.id;
						const lat = position.lat;
						const lon = position.lon;
						const createdAt = position.created_at;

						const tableCellMargin = { left: 100, right: 100 };

						const positionTableHeader = new TableRow({
							children: [
								new TableCell({
									margins: tableCellMargin,
									shading: {
										fill: 'D9D9D9', // Colore di riempimento grigio
									},
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: "Id",
													bold: true,
													color: '000000',
													size: 24.5,
												})
											]
										})
									]
								}),
								new TableCell({
									margins: tableCellMargin,
									shading: {
										fill: 'D9D9D9', // Colore di riempimento grigio
									},
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: "Latitudine",
													bold: true,
													color: '000000',
													size: 24.5,
												}),
											]
										}),
									]
								}),
								new TableCell({
									margins: tableCellMargin,
									shading: {
										fill: 'D9D9D9', // Colore di riempimento grigio
									},
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: "Longitudine",
													bold: true,
													color: '000000',
													size: 24.5,
												})
											]
										})
									]
								}),
								new TableCell({
									margins: tableCellMargin,
									shading: {
										fill: 'D9D9D9', // Colore di riempimento grigio
									},
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: "Data di creazione",
													bold: true,
													color: '000000',
													size: 24.5,
												})
											]
										})
									]
								}),
							]
						});

						const positionRow = new TableRow({
							children: [
								new TableCell({
									margins: tableCellMargin,
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: positionId.toString(),
													bold: true,
													color: '000000',
													size: 24.5,
												}),
											]
										}),
									]
								}),
								new TableCell({
									margins: tableCellMargin,
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: lat,
													color: '000000',
													size: 24.5,
												}),
											]
										}),
									]
								}),
								new TableCell({
									margins: tableCellMargin,
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: lon,
													color: '000000',
													size: 24.5,
												}),
											]
										}),
									]
								}),
								new TableCell({
									margins: tableCellMargin,
									children: [
										new Paragraph({
											children: [
												new TextRun({
													text: new Date(createdAt).toLocaleString(),
													color: '000000',
													size: 24.5,
												}),
											]
										}),
									]
								}),
							]
						});

						positionTable = new Table({
							alignment: AlignmentType.CENTER,
							columnWidths: [2250, 2250, 2250, 2250],
							rows: [
								positionTableHeader,
								positionRow
							],
						});
					}
					const infoTable = new Table({
						columnWidths: [4500, 4500], // Larghezza delle colonne
						rows: [
							// Intestazione grigia
							new TableRow({
								cantSplit: true, // Impedisce lo spezzamento della riga tra le pagine
								children: [
									new TableCell({
										columnSpan: 2, // Span su entrambe le colonne
										shading: {
											fill: 'D9D9D9', // Colore di riempimento grigio
										},
										children: [
											new Paragraph({
												alignment: AlignmentType.CENTER,
												children: [
													new TextRun({
														text: RIT,
														bold: true,
														size: 24.5,
														color: '000000',
													}),
												],
											}),
										],
									}),
								],
							}),

							// Righe con identificatori di riga e colonna
							new TableRow({
								children: [
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: 'Progressivo',
														size: 24.5,
														bold: true,
														color: '000000',
													}),
												],
											}),
										],
									}),
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: this.relevance.id.toString(),
														size: 24.5,
														color: '000000',
													}),
												],
											}),
										],
									}),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: 'Durata',
														size: 24.5,
														bold: true,
														color: '000000',
													}),
												],
											}),
										],
									}),
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: getDuration(this.relevance, this.$refs),
														size: 24.5,
														color: '000000',
													}),
												],
											}),
										],
									}),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: 'TIPO',
														size: 24.5,
														bold: true,
														color: '000000',
													}),
												],
											}),
										],
									}),
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: this.relevance ? "Captatore informatico – " + this.getTypeOfFileByValue(this.relevance) : "",
														size: 24.5,
														color: '000000',
													}),
												],
											}),
										],
									}),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: 'Data/Ora',
														size: 24.5,
														bold: true,
														color: '000000',
													}),
												],
											}),
										],
									}),
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: relevanceDate,
														size: 24.5,
														color: '000000',
													}),
												],
											}),
										],
									}),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: 'Interprete Traduttrice Lingua',
														size: 24.5,
														bold: true,
														color: '000000',
													}),
												],
											}),
										],
									}),
									new TableCell({
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: '',
														size: 24.5,
														color: '000000',
													}),
												],
											}),
										],
									}),
								],
							}),
						],
					});
					// Creazione del paragrafo per il testo "Ufficiale di P.G."
					const firmaText = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: exportDocumentInfos.titolo_firma,
								size: 24.5,
								color: '000000',
							}),
						],
					});

					// Creazione del paragrafo per la linea
					const linea = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: '____________________',
								bold: true,
								size: 24.5,
								color: '000000',
							}),
						],
					});

					// Creazione del paragrafo per il firmatario
					const firmatario = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: exportDocumentInfos.firmatario,
								size: 24.5,
								color: '000000',
							}),
						],
					});

					const doc = new Document({
						sections: [{
							properties: {},
							children: [
								// logo iniziale centrale
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new ImageRun({
											data: window.config.reportImageLogo,
											transformation: {
												width: 100,
												height: 100,
											},
											alignment: AlignmentType.CENTER,
										}),
									],
								}),
								// GUARDIA DI FINANZA
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: exportDocumentInfos.titolo,
											bold: true,
											color: '000000',
											size: 36,
										}),
									]
								}),
								// titolo Gruppo
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: exportDocumentInfos.gruppo,
											bold: true,
											color: '000000',
											size: 24.5,
										}),
									]
								}),
								// Nucleo Operativo
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: exportDocumentInfos.tipo_nucleo,
											color: '000000',
											size: 19,
										}),
									]
								}),
								// informazioni varie
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: exportDocumentInfos.informazioni_varie,
											color: '000000',
											size: 24.5,
										}),
									]
								}),
								// balnk space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 24.5,
										}),
									]
								}),
								// Tabella Oggetto
								new Table({
									columnWidths: [
										1700,
										6700,
									],
									rows: [
										oggettoTable
									]
								}),
								// balnk space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 24.5,
										}),
									]
								}),
								// Testo contenente informazioni inerenti al caso
								new Paragraph({
									alignment: AlignmentType.JUSTIFIED,
									children: [
										new TextRun({
											text: info_modulo,
											color: '000000',
											size: 24.5,
										}),
									]
								}),
								// blank space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 24.5,
										}),
									]
								}),
								// Tabella contenente le informazioni inerenti al caso
								infoTable,
								// blank space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 24.5,
										}),
									]
								}),
								transcriptionParagraph,
								ImageParagraph,
								keylogTable,
								positionTable,
								// balnk space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 50,
										}),
									]
								}),
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 50,
										}),
									]
								}),
								// Testo Conferma firma
								new Paragraph({
									alignment: AlignmentType.JUSTIFIED,
									children: [
										new TextRun({
											text: exportDocumentInfos.footer,
											color: '000000',
											size: 24.5,
										}),
									]
								}),
								// blank space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 24.5,
										}),
									]
								}),
								// Firma
								firmaText,
								// balnk space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 24.5,
										}),
									]
								}),
								linea,
								firmatario
							]
						}]
					});
					// get relevance id
					let saveIdExport = [this.relevance.id];
					Packer.toBlob(doc).then(blob => {
						saveAs(blob, this.relevance.id + ".docx");
						this.exportWordLoad = false;
					}).then(() => {
						axios.post(`${apiUrl}/device/relevance/set-is-export`, { relevanceIDS: saveIdExport }).then((response) => {
							console.log("response: ", response);
						});
					});
				}
				catch (error) {
					console.error(error);
				}
				finally {
					this.exportWordLoad = false;
				}
			},
			downloadFile(content, fileName) {
				const blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
				saveAs(blob, fileName);
			},
			async initSynthesisAndTranscription() {
				if (false === !!this.relevance.transcription) {
					await this.initTranscription()
				}
				if (false === !!this.relevance.synthesis) {
					await this.initSynthesis()
				}

				this.relevance.transcription.text = this.$refs.textarea.transcriptionText;
				this.relevance.transcription.userId = this.user.id;

				this.relevance.synthesis.text = this.$refs.textarea.sintesiText;
				this.relevance.synthesis.userId = this.user.id;
			},
			async saveRelevance(isF12 = false) {

				this.saving = true;
				this.relevance = SavedRelevance.relevance$._value;
				console.log("this.relevance: ", this.relevance);
				if (isF12) {
					this.relevance_grade.value = 2;
					this.relevance.relevance_grade = 2;
					this.relevance.synthesis.text = "Non Rilevante";
					console.log("this.relevance_F12: ", this.relevance);
				}
				if (this.relevance_grade.value == 0) {
					Swal.fire({
						title: 'Attenzione!',
						text: "La selezione 'Rilevanza non impostata' non è valida. Si prega di scegliere un diverso valore per il grado di rilevanza",
						icon: 'warning',
						confirmButtonColor: '#3085d6',
						confirmButtonText: 'OK'
					});
					this.saving = false;
					return;
				}
				await Relevance.api().put(`${apiUrl}/relevance`, { relevance: this.relevance }, {
					persistBy: 'insertOrUpdate',
					persistOptions: {
						insertOrUpdate: ['synthesis', 'transcriptions', 'users', 'relevanceEvents']
					}
				}).then(result => {
					SavedRelevance.setSavedRelevanceBool(false, "RELEVANCE 5");
					this.savedElement = false;
					Notification.insert({
						data: {
							icon: 'mdi-progress-upload',
							color: 'green darken-3',
							title: `Trascrizione salvata con successo`,
							subtitle: 'Trascrizione correttamente salvata sul server',
							actionLabel: 'OK',
							actionIcon: 'mdi-check',
							deviceId: this.relevance.device.id,
						}
					})
				}).catch((error) => {
					console.log(error);
					console.log("this.relevance: ", this.relevance);
					Notification.insert({
						data: {
							icon: 'mdi-progress-upload',
							color: 'red darken-3',
							title: `Errore durante il salvataggio`,
							subtitle: 'Non è stato possibile salvare la trascrizione nel server',
							actionLabel: 'OK',
							actionIcon: 'mdi-check',
							deviceId: this.relevance.device.id,
						}
					})
				})
				SocketioService.savedDataRelevance();
				this.saving = false;

				console.log("relevance_grade after: ", this.relevance_grade);
				console.log("relevance_note after: ", this.relevance_grade);
			},
			eventFocused(event) {
				this.$refs.waveform.seek(+event.happens_at)
			},
			async initTranscription() {
				if (false === !!this.relevance?.transcription) {
					const result = await Relevance.update({
						data: {
							id: this.$route.params.relevanceId,
							transcription: {
								text: "",
								relevance_grade: 0,
								created_at: (new Date).toISOString(),
								user: this.$store.getters.currentUser,
							}
						},
						insert: ['transcriptions'],
					})
				}
			},
			async initSynthesis() {
				if (false === !!this.relevance?.synthesis) {
					const result = await Relevance.update({
						data: {
							id: this.$route.params.relevanceId,
							synthesis: {
								text: "",
								relevance_grade: 0,
								created_at: (new Date).toISOString(),
								user: this.$store.getters.currentUser,
							}
						},
						insert: ['synthesis'],
					})

				}
			},
			async getRelevance() {
				const rev = await Relevance.api().get(`${apiUrl}/device/relevance/${this.$route.params.relevanceId}`, {
					persistBy: 'create',
					persistOptions: {
						create: [
							'files',
							'positions',
							'keylogs',
							'transcriptions',
							'synthesis',
							'relevanceEvents',
							'users'
						],
					}
				});
				this.relevance = rev.response.data;
				if (this.relevance.file) {
					this.lat = this.relevance.file.latitude;
					this.long = this.relevance.file.longitude;
					this.nameFile = apiUrl + '/downloadfile/' + this.relevance.file.name + '?access_token=' + this.token + '&deviceid=' + this.deviceId;
				}
				if (this.relevance.relevance_grade == null || this.relevance.relevance_grade == undefined) {
					this.relevance.relevance_grade = 0;
				}
				else {
					this.relevanceGrades.map((relevanceGrade) => {
						if (relevanceGrade.value == this.relevance.relevance_grade) {
							this.relevance_grade = relevanceGrade;
							return;
						}
					})
					console.log("this.relevance.relevance_grade: ", this.relevance.relevance_grade)
				}

				if (this.relevance?.synthesis?.relevance_grade != null && this.relevance?.synthesis?.relevance_grade != undefined) {
					this.relevance_notes.map((relevanceNote) => {
						if (relevanceNote.value == this.relevance.synthesis.relevance_grade) {
							this.relevance_note = relevanceNote;
							return;
						}
					})
				}
				else {
					this.relevance_note = {
						label: '',
						value: null
					};
				}
				if (this.relevance.transcription == null) {
					this.relevance.transcription = {
						text: "",
						relevance_grade: 0,
					}
				}

				if (this.relevance.synthesis == null) {
					this.relevance.synthesis = {
						text: "",
						relevance_grade: 0,
					}
				}
				this.relevance = this.relevance;
				let username = JSON.parse(sessionStorage.getItem("user")).username;
				console.log("USERNAME: ", username);
				let jsonSend = {
					relevanceId: this.relevance.id,
					action: 'insert',
					user: JSON.parse(sessionStorage.getItem("user")).username
				}
				SocketioService.sendMessage(jsonSend);
				console.log("RELEVANCE ELEM: ", this.relevance)
				SavedRelevance.setRelevance(this.relevance);
				if (this.relevance.type === 12) {
					this.convertDocxToPdfFromUrl();
				}
			},
			setDuration() {
				this.duration = getDuration(this.relevance, this.$refs);
				console.log("DURATION: ", this.duration);
			},
		},
		watch: {
			relevanceProgPrecAndSuc(actual, old) {
				if (actual !== old) {
					console.log("CI SONO CAMBIAMENTI");
					this.filterJSONToSend.filter_prog = actual;
					this.page = 1;
					this.getRelevancePrecAndSucc();
				}

			},
			relevanceGrade(actual, old) {
				if (actual !== old) {
					this.filterJSONToSend.filter_relevance = actual.value;
					this.filterJSONQueryParams.relevance = JSON.parse(JSON.stringify(actual));
					this.page = 1;
					this.getRelevancePrecAndSucc();
				}
			},
			relevanceNote(actual, old) {
				if (actual !== old) {
					this.filterJSONToSend.filter_note = actual.value;
					this.filterJSONQueryParams.note = JSON.parse(JSON.stringify(actual));
					this.page = 1;
					this.getRelevancePrecAndSucc();
				}
			},
			listenChoice(actual, old) {
				if (actual !== old) {
					this.filterJSONQueryParams.ascoltata = actual;
					this.filterJSONToSend.filter_listeining = actual.value;
					this.page = 1;
					this.getRelevancePrecAndSucc();
				}
			},
			typeChoice(actual, old) {
				if (actual !== old) {
					this.filterJSONQueryParams.tipologia = actual;
					this.filterJSONToSend.filter_type = actual.value;
					this.page = 1;
					this.getRelevancePrecAndSucc();
				}
			},
			filterDateFrom(actual, old) {
				if (actual !== old) {
					this.filterJSONQueryParams.dallaData = actual;
					this.filterJSONToSend.filter_date_from = actual;
					this.page = 1;
					this.getRelevancePrecAndSucc();
				}
			},
			filterTranscription(actual, old) {
				if (actual !== old) {
					this.filterJSONQueryParams.trascrizioneText = actual;
					this.filterJSONToSend.filter_transcription = actual;
					this.page = 1;
					this.getRelevancePrecAndSucc();
				}
			},
			filterProgNumber(actual, old) {
				if (actual !== old) {
					this.filterJSONToSend.filter_prog = actual;
					this.page = 1;
					this.getRelevancePrecAndSucc();
				}
			},
			filterSynthesis(actual, old) {
				if (actual !== old) {
					this.filterJSONQueryParams.sintesiText = actual;
					this.filterJSONToSend.filter_synthesis = actual;
					this.page = 1;
					this.getRelevancePrecAndSucc();
				}
			},
			filterDateTo(actual, old) {
				if (actual !== old) {
					this.filterJSONQueryParams.allaData = actual;
					this.filterJSONToSend.filter_date_to = actual;
					this.page = 1;
					this.getRelevancePrecAndSucc();
				}
			},
		},
	}
</script>

<style scoped>
	.border-bottom__divider {
		border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
	}

	.tooltip {
		width: 100%;
		position: relative;
		display: inline-block;
		border-bottom: 1px dotted black;
	}

	.v-card__title {
		padding: 0px !important;
	}

	.tooltip .tooltiptext {
		visibility: hidden;
		width: 120px;
		background-color: #555;
		color: #fff;
		text-align: center;
		font-size: 13px;
		border-radius: 6px;
		padding: 5px 0;
		position: absolute;
		z-index: 1;
		bottom: 125%;
		left: 50%;
		margin-left: -60px;
		opacity: 0;
		transition: opacity 0.3s;
	}

	.container-text {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.title-legend {
		font-weight: bold;
		font-size: 1vw;
	}

	.text-legend {
		margin-right: 10px;
	}

	.tooltip .tooltiptext::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: #555 transparent transparent transparent;
	}

	.btn-export {
		min-width: 32% !important;
		max-width: 30px;
		margin-top: 10px;
		margin-right: 3px;
		font-size: 0.6vw;
	}

	.padding-list {
		padding-left: 18px;
		padding-right: 18px;
	}

	.v-application--is-ltr .v-expansion-panel-header {
		text-align: left;
		font-size: 15px;
		font-size: 0.875rem;
		font-weight: 300;
		color: rgba(255, 255, 255, 0.7);
	}

	.v-expansion-panel-content__wrap {
		padding-bottom: 0px !important;
	}

	.container-element {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.tooltip:hover .tooltiptext {
		visibility: visible;
		opacity: 1;
	}

	.theme--dark.v-subheader {
		color: white;
		font-weight: bold;
	}

	.v-list-item {
		color: white;
		font-weight: bold;
		padding: 0px !important;
		min-height: 0px !important;
	}

	.v-card__text {
		padding: 0px !important;
	}

	.no-bold {
		font-weight: normal !important;
	}
</style>
