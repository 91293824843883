<template v-slot:extension>
	<div class="event-container">
		<template>
			<div class="event-container">
				<template v-for="(event, index) in eventArray">
					<div class="tooltip-container" :key="index">
						<v-btn color="red"
									 class="event-button"
									 @mouseover="showIcons = true"
									 @mouseleave="showIcons = false"
									 aria-label="Tooltip Bottom"
									 tooltip-position="bottom">
							{{ event.event_name }}
						</v-btn>
						<div class="tooltip btn-cont" :key="index">
							<div class="btn-cont__elem">
								<v-btn color="red"
											 @click="editEvent(event)"
											 class="btn-activity">
									<v-icon class="icon-btn">mdi-pencil</v-icon>
								</v-btn>
							</div>
							<div class="btn-cont__elem">
								<v-btn color="red"
											 @click="goToSecond(event.time)"
											 class="btn-activity">
									<v-icon class="icon-btn">mdi-play</v-icon>
								</v-btn>
							</div>
							<div class="btn-cont__elem">
								<v-btn color="red"
											 @click="deleteEvent(event.id)"
											 class="btn-activity">
									<v-icon class="icon-btn">mdi-delete</v-icon>
								</v-btn>
							</div>
						</div>
					</div>
				</template>
			</div>
		</template>
	</div>
</template>
<script>
	import { apiUrl } from '@/common/endpoint.const';
	import axios from '../../axios';
	import Swal from 'sweetalert2';

	export default {
		name: "EventDetails",
		components: {

		},
		props: [
			'editEvent',
			'relevanceId',
			'goToSecond'
		],
		async created() {
			await this.getEvent();
		},
		data() {
			return {
				eventArray: [],
				token: sessionStorage.getItem('token'),
			}
		},
		methods: {
			async getEvent() {
				await axios.get(`${apiUrl}/relevance/${this.relevanceId}/events-by-relevance`).then((response) => {
					console.log("response.data: ", response?.data?.events);
					this.eventArray = response?.data?.events;
				})
					.catch((error) => {
						console.log(error);
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: 'Errore nel caricamento degli eventi',
						})
					});
			},
			async deleteEvent(eventId) {
				// insert swal to confirm
				Swal.fire({
					icon: 'warning',
					title: 'Sei sicuro di voler eliminare questo evento?',
					showDenyButton: true,
					confirmButtonText: `Elimina`,
					denyButtonText: `Annulla`,
				}).then(async (result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						await axios.delete(`${apiUrl}/relevance/event/${eventId}`).then((response) => {
							console.log("response.data: ", response?.data);
							this.eventArray = response?.data?.events;
						})
							.catch((error) => {
								console.log(error);
								Swal.fire({
									icon: 'error',
									title: 'Oops...',
									text: 'Errore nel caricamento degli eventi',
								})
							});
						Swal.fire('Evento eliminato!', '', 'success')
					} else if (result.isDenied) {
						Swal.fire('Evento non eliminato', '', 'info')
					}
				})
			}
		}
	}
</script>
<style scoped>
	.event-button {
		margin-left: 10px;
	}

	.event-container {
		display: flex;
		flex: wrap;
		flex-wrap: nowrap;
		margin-top: 10px;
		margin-bottom: 34px;
		flex-wrap: wrap;
	}

	.btn-activity {
		height: 30px !important;
		min-width: 30px !important;
		width: 10px;
	}

	.icon-btn {
		font-size: 15px;
	}

	.btn-cont__elem {
		padding: 3px;
	}

	.tooltip-container {
		position: relative;
		display: inline-block;
		cursor: pointer;
		margin-top: 25px;
	}

	.btn-cont {
		display: flex;
		width: auto;
	}

	.tooltip {
		visibility: hidden;
		width: auto;
		height: auto;
		background-color: #000;
		color: #fff;
		text-align: center;
		border-radius: 4px;
		padding: 6px;
		position: absolute;
		z-index: 1;
		left: 55%;
		transform: translateX(-50%);
		opacity: 0;
		transition: opacity 0.3s ease;
	}

	.tooltip-container:hover .tooltip {
		visibility: visible;
		opacity: 1;
	}
</style>