<template>
	<div>
		<v-row class="pa-3">
			<v-col cols="9" class="d-flex justify-start align-center">
				<v-card @click="filterCall(null)" class="mx-4" style="width: 15%">
					<v-card-title>
						<v-icon color="grey darken-1" class="mr-1">mdi-playlist-check</v-icon> Tutte
					</v-card-title>
					<v-card-text>
						{{totalLength}} chiamate
					</v-card-text>
				</v-card>
				<v-card @click="filterCall(service)" v-for="(service,index) in services" class="mx-4" style="width: 15%" :key="index">
					<v-card-title>
						<v-icon :color="service.call_register_service | chatServiceColor" class="mr-1">{{service.call_register_service | chatServiceIcon}}</v-icon> {{service.call_register_service | callService}}
					</v-card-title>
					<v-card-text>
						{{service.count}} chiamate
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row align="center" class="pa-4">
			<v-col cols="6" v-if="callRegister" align-self="start">
				<v-card>
					<v-card-title>
						<v-icon class="ma-1">mdi-phone</v-icon>
						Chiamate recenti
						<v-spacer></v-spacer>
						Ordina:
						<v-icon class="ml-1" @click="toggleSort()">{{ `mdi-sort-clock-${sortOrder === 'ASC' ? 'descending' : 'ascending'}` }}</v-icon>
						<v-btn color="green"
							   block
							   @click="exportWord()"
							   :loading="exportWordLoad"
							   class="btn-export">
							<v-icon class="font-size__10px">mdi-file-word</v-icon>
						</v-btn>
					</v-card-title>
					<template>
						<v-col cols="">
							<div style="display: flex;align-items: center;height:0px;">
								<span>
									Seleziona tutti
								</span>
								<v-checkbox :label="''"
											color="primary"
											@click.stop
											@change="selectAllCall"
											v-model="selectAll" />
							</div>

						</v-col>
						<v-container>
							<v-list>
								<v-list-item-group>
									<template v-for="(item, index) in callRegister">
										<v-divider :key="'d'+item.id"></v-divider>
										<v-list-item @click.prevent="selectCall(item)">
											<v-checkbox :label="''"
														color="primary"
														@click.stop
														:value="callIDS.includes(item.id) || selectAll"
														@change="selectSingleCall(index, item.id)" />
											<v-list-item-avatar size="50px">
												<v-icon size="45px" :color="item.service | chatServiceColor" class="mr-1">
													{{ item.service | chatServiceIcon }}
												</v-icon>
											</v-list-item-avatar>
											<v-list-item-content>
												<v-list-item-title>
													{{ item.contact.known ? item.contact.name : 'Contatto sconosciuto' }}
												</v-list-item-title>
												<v-list-item-subtitle>
													{{ item.contact.phone }}
												</v-list-item-subtitle>
												<v-list-item-subtitle>
													<span :class="{lost: item.lost}">
														<v-icon>{{ item.type | comDirectionIcon }}</v-icon>
														Chiamata {{item.type | comDirectionLabel}}
														<span v-if="item.lost">(persa)</span>
													</span>
												</v-list-item-subtitle>
											</v-list-item-content>
											<v-list-item-action>
												<span class="text-caption">{{ getDataDifference(item.created_at) }}</span>
												<v-btn @click.prevent="selectCall(item)" icon>
													<v-icon color="grey lighten-1">mdi-information</v-icon>
												</v-btn>
											</v-list-item-action>
										</v-list-item>
									</template>
								</v-list-item-group>
							</v-list>
						</v-container>
					</template>
					<v-pagination v-model="page"
								  :total-visible="10"
								  @input="getNewData"
								  :length="Math.ceil(totalLengthPagination / 10)"
								  prev-icon="mdi-menu-left"
								  next-icon="mdi-menu-right">
					</v-pagination>
				</v-card>
			</v-col>
			<v-col cols="4" align-self="start">
				<v-subheader><v-icon class="ma-1">mdi-phone</v-icon>Dettagli chiamata</v-subheader>
				<v-divider></v-divider>
				<v-card v-if="!selectedCall">
					<v-card-text>
						Seleziona una chiamata dal registro per visualizzarne i dettagli
					</v-card-text>
				</v-card>
				<v-expand-transition>
					<v-card v-if="selectedCall">
						<v-list>
							<v-list-item>
								<v-row>
									<v-col cols="6">Inizio chiamata</v-col>
									<v-col cols="6" class="text-caption">
										<span>
											{{new Date(selectedCall.created_at).toLocaleString()}}
										</span>
									</v-col>
								</v-row>
							</v-list-item>

							<v-list-item v-if="!selectedCall.lost && selectedCall.ended_at">
								<v-row>
									<v-col cols="6">Fine chiamata</v-col>
									<v-col cols="6" class="text-caption">
										<span>
											{{new Date(selectedCall.ended_at).toLocaleString()}}
										</span>
									</v-col>
								</v-row>
							</v-list-item>

							<v-list-item v-if="!selectedCall.lost">
								<v-row>
									<v-col cols="6">Durata</v-col>
									<v-col cols="6" class="text-caption">
										<span>
											{{selectedCall.duration}}
										</span>
									</v-col>
								</v-row>
							</v-list-item>
						</v-list>
					</v-card>
				</v-expand-transition>

				<v-expand-transition>
					<v-card v-if="selectedCall">
						<v-subheader><v-icon class="ma-1">mdi-account</v-icon>Dettagli contatto</v-subheader>
						<v-divider></v-divider>
						<v-list-item>
							<v-row>
								<v-col cols="6">Nome</v-col>
								<v-col cols="6" class="text-caption">
									<span v-if="selectedCall.contact.known">{{selectedCall.contact.name}}</span>
									<span v-else>Sconosciuto<br />(id: {{selectedCall.contact.name}})</span>
								</v-col>
							</v-row>
						</v-list-item>
						<v-list-item>
							<v-row>
								<v-col cols="6">Telefono</v-col>
								<v-col cols="6" class="text-caption">
									<span>{{selectedCall.contact.phone}}</span>
								</v-col>
							</v-row>
						</v-list-item>
					</v-card>
				</v-expand-transition>

				<v-expand-transition>
					<v-card v-if="selectedCall">
						<v-subheader><v-icon class="ma-1">mdi-account-details</v-icon>Attività contatto</v-subheader>
						<v-divider></v-divider>
						<contact-activity-info :contact="selectedCall.contact"></contact-activity-info>
					</v-card>
				</v-expand-transition>

				<v-divider></v-divider>

				<v-card v-if="selectedCall?.relevanceId">
					<v-btn :to="`/device/relevance/${selectedCall.relevanceId}`">Vai alla pagina dell'acquisizione</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import Avatar from 'vue-avatar';
	import ContactActivityInfo from "@/components/contact/ContactActivityInfo";
	import DeviceInfo from "@/components/device/DeviceInfo";
	import CallRegister from "@/store/models/call-register.model";
	import { apiUrl } from "@/common/endpoint.const";
	import moment from "moment";
	import { addOneHour } from "@/common/utils";
	import axios from '../../axios';
	import {
		Document,
		Packer,
		Paragraph,
		TextRun,
		ImageRun,
		AlignmentType,
		TableCell,
		TableRow,
		Table,
	} from "docx";
	export default {
		name: "DeviceCallRegister",
		data() {
			return {
				selectedCall: null,
				sortOrder: 'DESC',
				sortField: 'name',
				searchField: '',
				contact: [],
				page: 1,
				selectAll: false,
				apiUrl,
				serviceFilter: "NULL",
				callRegister: [],
				callIDS: [],
				services: [],
				exportWordLoad: false,
				totalLength: 0,
				totalLengthPagination: 0,
			}
		},
		computed: {
			// calls(){
			//   if(this.device && this.device.callRegister){
			//     return this.device.callRegister.filter(call => this.serviceFilter ? call.service === this.serviceFilter : true)
			//   }
			//   return [];
			// },
		},
		components: {
			Avatar,
			ContactActivityInfo,
			DeviceInfo,
		},
		methods: {
			ProxyAddOneHour(date) {
				return addOneHour(date);
			},
			getDataDifference(date1) {
				const dateCall = new Date(date1);
				const today = new Date();
				const ms = 1000, s = 60, m = 60, h = 24;

				const diffTime = Math.abs(today - dateCall); // Differenza in millisecondi
				const diffDays = Math.floor(diffTime / (h * m * s * ms));
				const diffHours = Math.floor(diffTime / (m * s * ms));
				const diffMinutes = Math.floor(diffTime / (s * ms));

				let returnString = '';
				if (diffDays > 0) {
					returnString = `${diffDays} giorni fa`;
				} else if (diffHours > 0) {
					returnString = `${diffHours} ore fa`;
				} else {
					returnString = `${diffMinutes} minuti fa`;
				}

				return returnString;
			},
			selectAllCall() {
				if (this.selectAll) {
					this.callRegister.map((call, index) => {
						this.callIDS.push(call.id);
					});
				}
				else {
					this.callIDS = [];
				}
			},
			async selectSingleCall(index, id) {
				if (this.callIDS.includes(id) === false) {
					this.callIDS.push(id);
				}
				else {
					this.callIDS = this.callIDS.filter(call => call !== id);
				}
			},
			async getDataCallRegister() {
				const result = await CallRegister.api().post(`${apiUrl}/device/call-register`, {
					page: this.page,
					sortOrder: this.sortOrder,
					filter: {
						service: this.serviceFilter
					}
				});
				return result.response.data;
			},
			async getDataServiceUsage() {
				// return this.lodash.groupBy(this.device.callRegister, 'service')
				const result = await CallRegister.api().get(`${apiUrl}/device/service-usage`);
				console.log("Result: ", result.response.data);
				return result.response.data;
			},
			selectCall(call) {
				this.selectedCall = call;
			},
			async toggleSort() {
				this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
				let callRegisterData = await this.getDataCallRegister();
				this.callRegister = callRegisterData.response;
				this.totalLengthPagination = callRegisterData.count;
			},
			async getNewData() {
				let callRegisterData = await this.getDataCallRegister();
				console.log("Call Register Data: ", callRegisterData);
				this.callRegister = callRegisterData.response;
				this.totalLength = callRegisterData.count;
				this.totalLengthPagination = callRegisterData.count;
			},
			filterCall(service) {
				let parsingJSON = JSON.parse(JSON.stringify(service));
				if (parsingJSON == null) {
					this.serviceFilter = "NULL";
				} else {
					// get value of service that is an observer
					this.serviceFilter = parsingJSON.call_register_service;
				}
				this.page = 1;
				this.getNewData();
			},
			checkService(service) {
				console.log("Service: ", service)
				switch (service) {
					case "0":
						return "Telefono";
					case "1":
						return "WhatsApp";
					case "2":
						return "Telegram";
					case "3":
						return "Messenger";
					case "4":
						return "Instagram";
					case "5":
						return "WickrMe";
					default:
						return "Unknown";
				}
			},
			///TODO: Creare libreria separata per gestire esportazione documenti
			///per uniformare la procedura in tutti i file
			createTable({ rows, columnWidths, alignment }) {
				return new Table({
					alignment: alignment,
					columnWidths: columnWidths,
					rows: rows
				})
			},
			createRow({ children }) {
				return new TableRow({
					children: children
				})
			},
			createCell({ header = false, data = false, center = false, title, fontSize = null, bold = null, fontColor = '000000', alignment = null, text }) {
				var shading = null;
				var margins = null;
				if (header) {
					data = true;
					shading = {
						fill: "D9D9D9",
					};
				}
				if (data) {
					margins = {
						// In EMU, 1440 EMU = 1 pollice
						left: 80,
						right: 80,
					};
					bold = bold ?? true;
				}
				return new TableCell({
					shading: shading,
					margins: margins,
					children: [this.createText({
						alignment: alignment,
						center: center,
						bold: bold ?? false,
						size: fontSize,
						color: fontColor,
						text: text,
					})]
				});
			},
			createParagraph({ children, alignment }) {
				return new Paragraph({
					alignment: alignment,
					children: children
				})
			},
			createText({ text, center = false, title, size = null, bold = false, color = '000000', alignment = null }) {
				if (title || center) alignment = alignment ?? AlignmentType.CENTER
				switch (title) {
					case 1:
						size = size ?? 36;
						break;
					case 2:
						size = size ?? 24;
						break;
					case 3:
						size = size ?? 19;
						break;
					default:
						size = size ?? 20; // dimensione di default
						break;
				}

				return new Paragraph({
					alignment: alignment,
					children: [new TextRun({
						text: text,
						bold: bold,
						color: color,
						size: size,
					})]
				})
			},
			createImage({ data, width = 50, height = 50 }) {
				return new Paragraph({
					alignment: AlignmentType.CENTER,
					children: [new ImageRun({
						transformation: {
							width: width,
							height: height,
						},
						data: data,
					})]
				});
			},
			createDocumentHeader(exportDocumentInfos) {
				// Logo iniziale
				const logo = this.createImage({
					width: 100,
					height: 100,
					data: window.config.reportImageLogo,
				});
				// Guardia di finanza
				const titolo = this.createText({
					title: 1,
					bold: true,
					text: exportDocumentInfos.titolo,
				});
				// Titolo gruppo
				const gruppo = this.createText({
					title: 2,
					bold: true,
					text: exportDocumentInfos.gruppo,
				});
				// Nucleo Operativo
				const nucleo = this.createText({
					title: 3,
					text: exportDocumentInfos.tipo_nucleo,
				});
				// informazioni varie
				const info = this.createText({
					center: true,
					text: exportDocumentInfos.informazioni_varie,
				});
				return [
					logo,
					titolo,
					gruppo,
					nucleo,
					info,
				];
			},
			createDataTable(calls) {
				// Proprietà delle colonne
				// Lunghezza totale tabella = 4800
				const columns = {
					inizioChiamata: {
						header: "Inizio Chiamata",
						size: 1200,
					},
					fineChiamata: {
						header: "Fine Chiamata",
						size: 1200,
					},
					durata: {
						header: "Durata",
						size: 800,
					},
					telefono: {
						header: "Telefono",
						size: 1800,
					},
					nomeContatto: {
						header: "Nome Contatto",
						size: 2000,
					},
					servizio: {
						header: "Servizio",
						size: 1200,
					},
				};

				// Riga intestazione
				const callsTableHeader = this.createRow({
					children: Object.entries(columns).map(([key, value]) => this.createCell({
						header: true,
						text: value.header,
					}))
				});

				// Corpo
				const callsList = calls.map(call => this.createRow({
					children: Object.entries(columns).map(([key, value]) => {
						var text = "";
						switch (key) {
							case "inizioChiamata":
								text = new Date(call.created_at).toLocaleString();
								break;
							case "fineChiamata":
								text = new Date(call.ended_at).toLocaleString();
								break;
							case "durata":
								text = call.duration;
								break;
							case "telefono":
								text = call.phone_number;
								break;
							case "nomeContatto":
								text = call.contact.name;
								break;
							case "servizio":
								text = this.checkService(call.service);
								break;
						}
						return this.createCell({
							data: true,
							text: text,
						});
					})
				}));

				const columnWidths = Object.values(columns).map(column => column.size);

				return this.createTable({
					columnWidths: columnWidths,
					alignment: AlignmentType.CENTER,
					rows: [
						callsTableHeader,
						...callsList
					]
				});
			},
			createSignature(exportDocumentInfos, blankSpace) {
				// Creazione del paragrafo per il testo "Ufficiale di P.G."
				const firmaText = this.createText({
					center: true,
					size: 25,
					text: exportDocumentInfos.titolo_firma,
				});

				// Creazione del paragrafo per la linea
				const linea = this.createText({
					center: true,
					bold: true,
					text: '____________________',
				})

				// Creazione del paragrafo per il firmatario
				const firmatario = this.createText({
					center: true,
					size: 18,
					text: exportDocumentInfos.firmatario,
				})

				return [
					firmaText,
					blankSpace,
					linea,
					firmatario
				]
			},
			async exportWord() {
				try {
					this.exportWordLoad = true;
					console.log("Exporting word");
					console.log("Selected calls: ", this.selectElement);
					console.log("Selected calls IDS: ", this.callIDS);
					let calls;
					if (this.selectAll) {
						await axios.post(`${apiUrl}/device/getAllCalls`, { serviceFilter: this.serviceFilter }).then((response) => {
							calls = response.data;
						}).catch((error) => {
							console.log("Error: ", error);
						});
					}
					else {
						await axios.post(`${apiUrl}/device/getCallFromIDS`, { callIDS: this.callIDS }).then((response) => {
							calls = response.data;
						}).catch((error) => {
							console.log("Error: ", error);
						});
					}
					if (calls != undefined) {
						var exportDocumentInfos = (await axios.get(`${apiUrl}/device/get-document-export`)).data;

						let today = new Date();
						let dd = today.getDate();
						let mm = today.getMonth() + 1;
						let yyyy = today.getFullYear();
						let hh = today.getHours();
						let min = today.getMinutes() + "";
						console.log(dd + "/" + mm + "/" + yyyy + " " + hh + ":" + min);
						let oggetto = exportDocumentInfos.oggetto
							.replace(new RegExp(this.escapeRegExp('{{progressivo}}'), 'g'), "Esportazione chiamate");
						let info_modulo = exportDocumentInfos.info_modulo
							.replace(new RegExp(this.escapeRegExp('{{anno}}'), 'g'), yyyy)
							.replace(new RegExp(this.escapeRegExp('{{mese}}'), 'g'), String(mm).padStart(2, '0'))
							.replace(new RegExp(this.escapeRegExp('{{giorno}}'), 'g'), String(dd).padStart(2, '0'))
							.replace(new RegExp(this.escapeRegExp('{{orario}}'), 'g'), String(hh).padStart(2, '0') + ":" + String(min).padStart(2, '0'))
							.replace(new RegExp(this.escapeRegExp('{{firmatario}}'), 'g'), exportDocumentInfos.firmatario)
							.replace(new RegExp(this.escapeRegExp('{{RIT}}'), 'g'), exportDocumentInfos.rit_acquisizioni);

						const blankSpace = this.createText({
							text: '',
						});

						const biggerBlankSpace = this.createText({
							text: '',
							size: 50,
						});

						const header = this.createDocumentHeader(exportDocumentInfos);

						// Tabella Oggetto
						const oggettoTable = this.createTable({
							columnWidths: [
								1700,
								6700,
							],
							rows: [this.createRow({
								children: [
									this.createCell({
										bold: true,
										text: "Oggetto",
									}),
									this.createCell({
										text: oggetto,
									}),
								]
							})]
						});

						// Testo contenente informazioni inerenti al caso
						const infoModulo = this.createText({
							alignment: AlignmentType.JUSTIFIED,
							text: info_modulo
						});

						// Immagine di divisione
						const divider = this.createImage({
							width: 100,
							height: 50,
							data: window.config.reportImageDivider,
						});

						// Titolo sezione dati
						const titolo = this.createText({
							title: 2,
							bold: true,
							text: "ESPORTAZIONE REGISTRO CHIAMATE",
						});

						// Tabelle chiamate
						const callsTable = this.createDataTable(calls);

						const signature = this.createSignature(exportDocumentInfos, blankSpace);

						const doc = new Document({
							sections: [{
								properties: {},
								children: [
									...header,
									oggettoTable,
									blankSpace,
									infoModulo,
									divider,
									blankSpace,
									titolo,
									blankSpace,
									blankSpace,
									biggerBlankSpace,
									biggerBlankSpace,
									callsTable,
									...signature
								]
							}]
						});
						Packer.toBlob(doc).then(blob => {
							saveAs(blob, "call-register.docx");
							this.exportWordLoad = false;
						});
					}
					this.exportWordLoad = false;
				}
				catch (e) {
					console.log("Error: ", e);
				}
				finally {
					this.exportWordLoad = false;
				}
			},
			escapeRegExp(string) {
				return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
			},
		},
		async created() {
			let callRegisterData = await this.getDataCallRegister();
			this.callRegister = callRegisterData.response;
			this.totalLength = callRegisterData.count;
			this.totalLengthPagination = callRegisterData.count;
			this.services = await this.getDataServiceUsage();
		}
	}
</script>

<style scoped>
	.lost {
		color: red;
	}

	.btn-export {
		min-width: 50px !important;
		max-width: 30px;
		margin-top: 10px;
		margin-right: 3px;
		font-size: 0.3vw;
	}
</style>