<template>
	<div>
		<v-row class="align-content-center">
			<v-col cols="4"></v-col>
			<v-col cols="4" class="pa-4">
				<v-card>
					<v-card-title>Login</v-card-title>
					<v-card-text class="text-caption">
						<span>Effettua il login con il tuo account operatore</span>
						<v-text-field placeholder="username"
													v-model="email"
													color="primary"></v-text-field>
						<v-text-field placeholder="password"
													v-model="password"
													type="password"
													color="primary"></v-text-field>
					</v-card-text>
					<v-card-actions>
						<v-btn block dark color="primary" @click="login">Accedi</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { LOGIN, AUTHENTICATE, FETCH_ALL_DEVICES } from "@/store/actions.type";
	import { SOCKET_CONNECT } from "@/store/actions.type";
	import { SET_DEVICE, SET_AUTH } from "@/store/mutations.type";
	import Device from "@/store/models/device.model";
	export default {
		name: "Login",
		created() {
			window.addEventListener('keydown', this.handleKeyDown);
			if (sessionStorage.getItem('user') != null) {
				const deviceId = sessionStorage.getItem('deviceId')
				const user = sessionStorage.getItem('user')
				const token = sessionStorage.getItem('token');
				sessionStorage.setItem('user', user);
				sessionStorage.setItem('token', token);
				//sessionStorage.setItem('deviceId', deviceId);
				Device.dispatch(FETCH_ALL_DEVICES).then(_ => {
					var devices = Device.query().all();
					console.log("Devices: ", devices);
					var device = Device.query().with('state').with('batteryRegister').with('trafficRegister').all();
					device = device.filter(d => d.id == deviceId)[0];
					Device.dispatch(SET_DEVICE, device);
					this.$store.dispatch(AUTHENTICATE);
					this.$store.commit(SET_AUTH, { user: JSON.parse(user), access_token: token });
					var lastPage = JSON.parse(sessionStorage.getItem('lastPage'));
					if (lastPage?.name) {
						this.$router.push({ name: lastPage.name });
					}
				});
			}
		},
		beforeDestroy() {
			window.removeEventListener('keydown', this.handleKeyDown);
		},
		data() {

			return {
				email: "",
				password: ""
			}
		},
		methods: {
			handleKeyDown(e) {
				if (e.key == "Enter") {
					console.log(e.key);
					this.login();
				}
			},
			login() {
				this.$store.dispatch(LOGIN, { username: this.email, password: this.password })
					.then(async () => {
						await this.$store.dispatch(SOCKET_CONNECT)

						if (this.$store.getters.currentUser.id) {
							this.$router.push('/twofactorregistration');
						}
					})
					.catch(err => console.log(err));
			}
		}
	}
</script>