<template>
    <v-card class="mx-auto" v-if="device">
        <v-list-item two-line>
            <v-list-item-content>
                <v-list-item-title class="headline"> Lista Permessi </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list subheader class="transparent">
            <v-list-item>
                <v-list-item-title>Versione Android: {{device.state.so_version}}</v-list-item-title>
                <v-btn
                    color="green"
                    class="event-button"
                    aria-label="Tooltip Bottom" 
                    tooltip-position="bottom"
                    @click="sendCommand('GET_DEVICE_VERSION')"
                >
                    Ottieni Versione
                </v-btn>
            </v-list-item>
            
            <v-list-item>
                <v-list-item-title>Accessibilità(isAccessibilitySettingsOn)</v-list-item-title>
                <v-btn
                    color="green"
                    class="event-button"
                    aria-label="Tooltip Bottom" 
                    tooltip-position="bottom"
                    @click="sendCommand('ACC')"
                >
                    Apri Menù
                </v-btn>
                <v-list-item-icon>
                    <v-icon :color="device.state.isAccessibilitySettingsOn ? 'green' : 'red'">mdi-human</v-icon>
                </v-list-item-icon>
            </v-list-item>
            

            <v-list-item>
                <v-list-item-title>Ottimizzazione Batteria(isBatteryOptimizationIgnored)</v-list-item-title>
                <v-btn
                    color="green"
                    class="event-button"
                    aria-label="Tooltip Bottom" 
                    tooltip-position="bottom"
                    @click="sendCommand('BATTERY_DEFAULT')"
                >
                    Apri Menù
                </v-btn>
                <v-list-item-icon>
                    <v-icon :color="device.state.isBatteryOptimizationIgnored ? 'green' : 'red'">
                        mdi-battery
                    </v-icon>
                </v-list-item-icon>
            </v-list-item>
            <!-- 
            <v-list-item> 
                <v-list-item-title>Stats(isUsageStatsEnabled)</v-list-item-title> 
                <v-list-item-icon> 
                    <v-icon :color="device.state.isUsageStatsEnabled ? 'green' : 'red'" >
                        mdi-poll
                    </v-icon> 
                </v-list-item-icon> 
            </v-list-item> 
            -->
            <v-list-item>
                <v-list-item-title>Notifiche(isNotificationListenerOn)</v-list-item-title>
                <v-btn
                    color="green"
                    class="event-button"
                    aria-label="Tooltip Bottom" 
                    tooltip-position="bottom"
                    @click="sendCommand('NOTY')"
                >
                    Apri Menù
                </v-btn>
                <v-list-item-icon>
                    <v-icon :color="device.state.isNotificationListenerOn ? 'green' : 'red'">mdi-bell</v-icon>
                </v-list-item-icon>
            </v-list-item>

            <v-list-item>
                <v-list-item-title>Amministratore(isAdmin)</v-list-item-title>
                <v-btn
                    color="green"
                    class="event-button"
                    aria-label="Tooltip Bottom" 
                    tooltip-position="bottom"
                    @click="sendCommand('DEVICE_ADMIN')"
                >
                    Apri Menù
                </v-btn>
                <v-list-item-icon>
                    <v-icon :color="device.state.isAdmin ? 'green' : 'red'">mdi-security</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>Avvio Automatico(isAutomaticPermission)</v-list-item-title>
                <v-btn
                    color="green"
                    class="event-button"
                    aria-label="Tooltip Bottom" 
                    tooltip-position="bottom"
                    @click="sendCommand('ACTIVE_BATTERY')"
                >
                    Apri Menù
                </v-btn>
                <v-list-item-icon>
                    <v-icon :color="device.state.isAutomaticPermission ? 'green' : 'red'">mdi-refresh-auto</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>Informazioni applicazioni</v-list-item-title>
                <v-btn
                    color="green"
                    class="event-button"
                    aria-label="Tooltip Bottom" 
                    tooltip-position="bottom"
                    @click="sendCommand('GO_IN_INFO_APP')"
                >
                    Apri Menù
                </v-btn>
                <v-list-item-icon>
                    <v-icon :color="'green'">mdi-shield-crown</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <div class="btn-cont">
            <v-btn
                :color="isAutoInstallCheck ? 'green' : 'red'"
                class="event-button"
                aria-label="Tooltip Bottom" 
                tooltip-position="bottom"
                style="margin-right: 5px;"
                @click="sendCommand('CAHNGE_AUTOINSTALL_STATUS')"
            >
                <span v-if="isAutoInstallCheck">Ferma l'autoinstallazione</span>
                <span v-else>Avvia l'autoinstallazione</span>
            </v-btn>
            <v-btn
                color="green"
                class="event-button"
                aria-label="Tooltip Bottom" 
                tooltip-position="bottom"
                @click="sendCommand('GET_VALUE_OF_AUTOINSTALL')"
            >
                <v-icon>mdi-update</v-icon>
            </v-btn>
        </div>
    </v-card>
</template>
<script>
	import { FETCH_DEVICE_PERMISSIONS } from "@/store/actions.type";
	import Console from "@/store/models/console.model";
	import Device from "@/store/models/device.model";
	import axios from '../../axios';
	import { apiUrl } from "@/common/endpoint.const";
	export default {
		name: "DevicePermission",
		props: ["device"],
		data() {
			return {
				console: null,
				isAutoInstallCheck: null
			};
		},
		//watch: {
		//	// Aggiungi un watcher per monitorare i cambiamenti della proprietà
		//	'device.state.isAutoInstallCheck': function (newVal, oldVal) {
		//		console.log(`isAccessibilitySettingsOn changed from ${oldVal} to ${newVal}`);
		//      // Esegui il codice desiderato qui
		//      isAutoInstallCheck = newVal;
		//	}
		//},
		methods: {
			async sendCommand(command) {
				await this.console.sendCommand(
					{
						cmd: command,
						params: [],
						user: JSON.parse(sessionStorage.getItem('user')) || {}
					},
					sessionStorage.getItem("deviceId")
				);
				await new Promise(r => setTimeout(r, 4000));
				if (command == 'CAHNGE_AUTOINSTALL_STATUS' || command == 'GET_VALUE_OF_AUTOINSTALL') {
					await this.getAutoInstall();
				}

			},
			async getAutoInstall() {
				await axios.get(`${apiUrl}/device/getCheckAutoInstallValue`).then((data) => {
					console.log("res: ", data.data);
					let result = data.data;
					this.isAutoInstallCheck = result;
				});
			}

		},
		async created() {
			if (!this.console) {
				console.log('[CREATING CONSOLE...]')
				await Console.insertOrUpdate({
					data: {
						deviceId: sessionStorage.getItem("deviceId"),
						device: this.device,
					}
				}).then((data) => {
					this.console = data.console[0];
				});
			}
		},
		async mounted() {
			await this.getAutoInstall();
			await Device.dispatch(FETCH_DEVICE_PERMISSIONS, { deviceId: sessionStorage.getItem("deviceId") })
		}
	};
</script>
<style scoped lang="scss">
    .btn-cont{
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 20px;
        button{
            width: 50%;
        }
    }
</style>