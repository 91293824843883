
<template>
	<div v-if="device">
		<v-row v-if="false">
			<v-col cols="3">
				<v-banner>Cartelle rilevanti</v-banner>
			</v-col>
			<v-col cols="3">
				<v-card class="ma-2" @click="setActive(95)">
					<v-card-title>
						<v-icon color="green">mdi-whatsapp</v-icon> Whatsapp
					</v-card-title>
				</v-card>
			</v-col>
			<v-col cols="3">
				<v-card class="ma-2" @click="setActive(74)">
					<v-card-title>
						<v-icon color="light-blue">mdi-send-circle</v-icon> Telegram
					</v-card-title>
				</v-card>
			</v-col>
			<v-col cols="3">
				<v-card class="ma-2" @click="setActive(4)">
					<v-card-title>
						<v-icon color="yellow darken-3">mdi-download</v-icon> Download
					</v-card-title>
				</v-card>
			</v-col>
		</v-row>
		<v-row align="center" class="pa-4">
			<v-col cols="3">
				<v-row>
					<v-col cols="12">
						<v-card style="min-height:100%; height:100%; max-height:900px !important; overflow-y: auto;">
							<div style="padding:10px;">
								<h4>Visualizza l'albero delle cartelle</h4>
							</div>

							<template>
								<v-treeview :active.sync="active"
														:items="items"
														:load-children="loadFolderChildren"
														:open.sync="open"
														v-model:opened="zeroOpen"
														activatable
														color="warning"
														open-on-click
														transition
														@update:active="getNodeOffileById">
									<template v-slot:prepend="{ item }">
										<v-icon :color="item.extension | fileExtensionColor">{{item.extension | fileExtensionIcon}}</v-icon>
									</template>
								</v-treeview>
							</template>
						</v-card>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-card style="min-height:100%; height:100%; max-height:900px !important; overflow-y: auto;">
							<template>
								<div style="padding:10px;">
									<h4>Percorsi Rapidi</h4>
								</div>
								<v-treeview activatable
														:active.sync="fastActive"
														transition
														:items="generateRapidPath"
														@update:active="getFileSystemFromLastWord"
														:item-key="'name'">
									<template v-slot:prepend="{ item }">
										<v-icon style="color:#f9a825 !important;">mdi-folder</v-icon>
									</template>
								</v-treeview>
							</template>
						</v-card>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-card style="min-height:100%; height:100%; max-height:900px !important; overflow-y: auto;">
							<template>
								<div style="padding:10px;">
									<h4>Visualizza tutti i file scaricati ({{ fileScaricati.count }})</h4>
								</div>
								<div style="display: flex;">
									<v-text-field v-model="textToFilter"
																label="Filtra file"
																style="flex: 1;"></v-text-field>
									<v-btn v-if="textToFilter != ''"
												 @click="textToFilter = ''"
												 style="flex: 0 0 auto;"><v-icon>mdi-close</v-icon></v-btn>
								</div>
								<v-treeview activatable
														:active.sync="downloadedActive"
														:items="downloadedFile"
														@update:active="showFile"
														@input="downloadedFileFunc">
									<template v-slot:prepend="{ item }">
										<v-icon :color="item.extension | fileExtensionColor">{{item.extension | fileExtensionIcon}}</v-icon>
									</template>
								</v-treeview>
								<v-pagination :total-visible="0"
															v-model="pageFileScaricati"
															:length="Math.ceil(fileScaricati.count / 10)"
															@input="changePageFileScaricati"></v-pagination>
							</template>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="9" align-self="start">
				<v-row>
					<v-col cols="12" align-self="start">
						<v-card>
							<div style="display: flex; align-items: center;">
								<v-text-field @keyup.enter="searchFile"
															label="Ricerca file:"
															v-model="searchInput"
															style="flex:1; margin: 5px;"></v-text-field>
								<v-btn @click="searchFile"><v-icon>mdi-magnify</v-icon></v-btn>
								<v-btn v-if="searching" @click="clearSearch" style="flex: 0 0 auto;"><v-icon>mdi-close</v-icon></v-btn>
							</div>
						</v-card>
						<v-divider></v-divider>
						<v-card>
							<v-card-title v-if="!searching">
								<div style="display:flex;justify-content:space-between;width:100%;">
									<div style="display:flex;">
										<v-btn icon @click="showDeleted = !showDeleted">
											<v-icon color="primary">
												{{showDeleted ? 'mdi-eye' : 'mdi-eye-off'}}
											</v-icon>
										</v-btn>
										<v-btn icon @click="refreshDir()">
											<v-icon color="primary">
												mdi-refresh
											</v-icon>
										</v-btn>
										<v-btn icon v-if="(filesystem != null && filesystem[0] != null && filesystem[0].remote_path != '/storage/emulated/0') || filesystem.length == 0" @click="returnBack()">
											<v-icon color="yellow darken-3">
												mdi-folder-upload
											</v-icon>
										</v-btn>
									</div>
									<div style="display:flex;">
										<span v-for="text in splitElem()" :key="text">
											<v-btn @click="getFileSystemByText(text)">
												{{ text }}
											</v-btn>
											<span v-if="text !== splitElem()[splitElem().length - 1]">
												> &nbsp;
											</span>
										</span>
									</div>
								</div>
							</v-card-title>

							<v-divider></v-divider>
							<v-card-text v-if="filesystem.length == 0">Non ci sono file all'interno di questa cartella</v-card-text>
							<v-card-text v-else style="max-height: 600px; min-height: 600px; overflow-y: scroll; overflow-x: hidden;">
								<v-row class="ma-1">
									<template v-for="(fileElem,index) in filesystem">
										<v-col cols="4" v-if="(showDeleted && !fileElem.remote_deleted) || (!showDeleted)" :key="fileElem.id">
											<v-card :class="{'darken-3': fileElem.id === activeNodeId}">
												<v-card-title style="cursor:pointer;" @click="(!fileElem.remote_deleted && fileElem.was_downloaded) || fileElem.is_dir ? setActive(fileElem.id,fileElem) : () => {}">
													<v-icon :color="fileElem.extension | fileExtensionColor">
														{{fileElem.extension | fileExtensionIcon}}
													</v-icon>

													{{fileElem.name}}
												</v-card-title>
												<v-card-text>
													<div style="display:flex;justify-content:space-between;align-items: center;gap:10px;">
														<span>Peso File: {{ formatBytesToMegabytes(fileElem.size)[0]+" "+formatBytesToMegabytes(fileElem.size)[1] }}</span>
														<!-- format fileEmel.created_at in date format -->
														<span>Data: {{ ProxyAddOneHour(fileElem.last_modified) }}</span>
													</div>
													<span v-if="searching">{{ fileElem.remote_path }}</span>
													<div style="display:flex;justify-content:space-between;align-items: center;gap:10px;">
														<v-card-actions v-if="!fileElem.is_dir">
															<v-spacer></v-spacer>
															<v-btn v-if="!fileElem.remote_deleted && indexDownloading == index"><v-progress-circular indeterminate></v-progress-circular></v-btn>
															<v-btn v-if="fileElem.was_downloaded" @click="showFile(fileElem)">Apri</v-btn>
															<v-btn v-if="!fileElem.remote_deleted && (indexDownloading == null || indexDownloading != index) && !fileElem.was_downloaded" @click="downloadFile(fileElem,index)">Scarica</v-btn>
														</v-card-actions>
														<div>

															<div class="tooltip-container">
																<v-icon v-if="!fileElem.is_dir && fileElem.relevance">
																	mdi-monitor-arrow-down-variant
																</v-icon>
																<div class="tooltip btn-cont">
																	<span class="tooltip-text">Rilevanza già creata</span>
																</div>
															</div>
															<div class="tooltip-container">
																<v-icon v-if="fileElem.remote_deleted">
																	mdi-trash-can
																</v-icon>
																<div class="tooltip btn-cont">
																	<span class="tooltip-text">File rimosso dal telefono</span>
																</div>
															</div>

														</div>
													</div>
												</v-card-text>
											</v-card>
										</v-col>
									</template>

								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-dialog width="40%" v-model="showingFile" @click:outside="closeDialog">
			<v-card v-if="fileToShow">
				<v-card-title>
					{{fileToShow.name}}
					<v-spacer></v-spacer>
					<v-btn v-if="relevanceIsCreated" @click="createRelevanceFromFile(fileToShow)">
						<v-icon>mdi-plus</v-icon>
						Crea rilevanza
					</v-btn>
				</v-card-title>
				<v-card-text>
					<template v-if="actualChatData.length > 0">
						<span>
							<v-icon>mdi-chat</v-icon>
							<span v-if="actualChatData.length == 1">Il file è inserito all'interno del seguente contatto:</span>
							<span v-if="actualChatData.length > 1">Il file e incerto è può trovarsi all'interno delle chat dei seguenti contatti:</span>
							<span>
								<v-icon>mdi-whatsapp</v-icon>
								<span v-for="(chat, index) in actualChatData" :key="index">
									{{ chat.name }} alla data {{ ProxyAddOneHour(chat.created_at) }}
									<span v-if="index != actualChatData.length - 1">,</span>
								</span>
							</span>
						</span>
					</template>
				</v-card-text>
				<v-card-text class="d-flex justify-center align-center">
					<v-img v-if="fileToShow.extension === 'image'" height="50%" width="50%" :src="nameFile"></v-img>
					<Media v-else-if="fileToShow.extension === 'audio'"
								 :kind="'video'"
								 :controls="true"
								 :controlsList="'nodownload'"
								 :src="nameFile">
					</Media>

					<video controls :key="videoKey" v-else-if="fileToShow.extension === 'video'" :style="{ maxWidth: '80%', maxHeight: '80%' }" controlsList="nodownload" disablePictureInPicture>
						<source :src="nameFile">
					</video>
					<pdf v-else-if="fileToShow.extension === 'pdf'" :src="nameFile"></pdf>
					<iframe v-else-if="fileToShow.extension === 'doc-text'" :srcdoc="docxHTML" height="600px" width="100%"></iframe>
					<p v-else>Impossibile aprire questo tipo di file</p>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
	import pdf from 'vue-pdf'
	import Device from "@/store/models/device.model";
	import DeviceInfo from "@/components/device/DeviceInfo";

	import {
		DOWNLOAD_FILE,
		REFRESH_DIRECTORY_COMMAND
	} from "@/store/actions.type";
	import { apiUrl } from "@/common/endpoint.const";
	import File from "@/store/models/file.model";
	import Media from '@dongido/vue-viaudio';
	import Notification from "@/store/models/notification.model";
	import { addOneHour } from '../../common/utils';
	import axios from '../../axios';
	import SavedRelevance from "../../services/savedRelevance.service";
	import Vue from "vue";

	var mammoth = require('mammoth');

	export default {
		name: "DeviceFilesystem",
		data() {
			return {
				fileScaricati: { count: 0, data: [] },
				filesystem: [],
				downloadedFile: [],
				indexDownloading: null,
				actualFolder: "/storage/emulated/0",
				docxHTML: "",
				activeNodeId: 1,
				rapidPath: [],
				relevanceIsCreated: false,
				active: [],
				fastActive: [],
				downloadedActive: [],
				timeEpoch: window.config.epochUpdate,
				open: [1],
				showDeleted: false,
				firstTime: false,
				showFileBkp: null,
				nameFile: null,
				pdfUrl: null,
				activeParentId: null,
				actualChatData: [],
				pageFileScaricati: 1,
				pdfBlob: null,
				showingFile: false,
				docxHTML: "",
				fileToShow: null,
				apiUrl,
				token: sessionStorage.getItem('token'),
				deviceId: sessionStorage.getItem('deviceId'),
				textToFilter: "",
				searchInput: "",
				searching: false,
				items: [],
			}
		},
		components: {
			pdf,
			DeviceInfo,
			Media
		},
		watch: {
			actives(n) {
				this.activeNodeId = n[0];
			},
			textToFilter(filter) {
				this.downloadedFileFunc();
			}
		},
		methods: {
			closeDialog() {
				this.showingFile = false;
				this.active = [];
				this.fastActive = [];
				this.downloadedActive = [];
				this.fileToShow = {};
				this.nameFile = '';
			},
			async searchFile() {
				this.searching = true;
				await axios.post(`${apiUrl}/device/filesystem/search`,
					{
						text: this.searchInput
					}).then((result) => {
						this.filesystem = result.data;
					});
			},
			async clearSearch() {
				this.searchInput = '';
				this.initFileSystem();
				this.searching = false;
			},
			async initFileSystem() {
				this.filesystem = (await File.api().get(`${apiUrl}/device/filesystem?root=1`)).response.data;
				this.activeNodeId = this.filesystem[0].parentId;
				this.downloadedFileFunc();

				if (this.items.length == 0) {
					this.items.push({
						id: 1,
						name: '0',
						extension: 'dir',
						children: [],
					});
				}
			},
			async createRelevanceFromFile(file) {
				await SavedRelevance.createRelevanceFromFile(file.id);
				this.relevanceIsCreated = false;
				let index = this.filesystem.findIndex(x => x.id == file.id);
				let dataRelevance = await SavedRelevance.getDataRelevance(file.id);
				this.filesystem[index].relevance = dataRelevance;
				console.log("dataRelevance: ", this.filesystem[index].relevance);
			},
			ProxyAddOneHour(date) {
				return addOneHour(date);
			},
			splitElem() {
				if (this.filesystem[0] != null && this.filesystem[0] != undefined) {
					let splitted = this.filesystem[0].remote_path.split("/storage/emulated/")[1];
					return splitted.split("/");
				}
				else {
					let splitted = this.actualFolder.split("/storage/emulated/")[1];
					return splitted.split("/");
				}
			},
			async setActive(node, fileElem = null) {
				if (this.searching) return;
				if (typeof node === 'string' || typeof node === 'number') {
					this.activeNodeId = node;
					if (fileElem?.is_dir) {
						let res = (await File.api().get(`${apiUrl}/device/filesystem?root=0&parentId=${node}&timeEpoch=${this.timeEpoch}`)).response.data;
						console.log(fileElem);
						this.actualFolder = fileElem.remote_path + "/" + fileElem.name;
						this.filesystem = res;
					}
				}
				else {
					if (!node.is_dir) {
						this.showFile(node);
					}
					this.activeNodeId = node.id;
				}
			},
			returnBack() {
				let splitArr = this.splitElem();
				this.getFileSystemByText(splitArr[splitArr.length - 2]);
			},
			changePageFileScaricati(page) {
				this.pageFileScaricati = page;
				this.downloadedFileFunc();
			},
			async getFileSystemByText(text) {
				let startConcat = true;
				let stringSplit = "/storage/emulated/";
				this.splitElem().map(elem => {
					if (startConcat) {
						stringSplit += elem + "/";
					}
					if (elem == text) {
						startConcat = false;
					}
				});
				stringSplit = stringSplit.substring(0, stringSplit.length - 1);
				this.getFileSystemFromRemotePath(stringSplit);
			},
			async getFileSystemFromRemotePath(stringSplit) {
				this.filesystem = (await File.api().post(`${apiUrl}/device/filesystem/remote_path`, { remote_path: stringSplit, timeEpoch: this.timeEpoch })).response.data;
				this.activeNodeId = this.filesystem[0].parentId;
			},
			async getFileSystemFromLastWord(lastWord) {
				if (!lastWord || lastWord.length == 0) return;
				if (this.searching) return;
				let data = (await File.api().get(`${apiUrl}/device/filesystem/get/last/word/remote/${lastWord}/${this.timeEpoch}`)).response.data;
				if (data.length > 0) {
					this.filesystem = data;
					this.activeNodeId = this.filesystem[0].parentId;
				}
				else {
					Notification.insert({
						data: {
							icon: 'mdi-progress-upload',
							color: 'red darken-1',
							title: `File non trovato`,
							subtitle: 'Il percorso rapido non è presente all\interno di questo dispositivo!',
							actionLabel: 'OK',
							actionIcon: 'mdi-check',
							deviceId: sessionStorage.getItem("deviceId"),
						}
					});
				}
			},
			formatBytesToMegabytes(bytes, decimals = 2) {
				// convert bytes to megabytes
				if (!+bytes) return '0 Bytes'

				const k = 1024
				const dm = decimals < 0 ? 0 : decimals
				const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

				const i = Math.floor(Math.log(bytes) / Math.log(k))

				return [parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), sizes[i]];
			},
			async convertDocxToPdfFromUrl() {
				let res = await fetch(`${apiUrl}/${this.fileToShow.local_path}`);
				// convert res to buffer
				res.arrayBuffer().then(async (arrayBuffer) => {
					//convert array buffer to buffer
					const buffer = Buffer.from(arrayBuffer);
					const html = await mammoth.convertToHtml({ arrayBuffer: buffer });
					// insert html into iframe
					this.docxHTML = "<html><head><style>body{background-color:white;}</style></head><body>" + html.value + "</body></html>";
				});
				// convert buffer to pdf
			},
			async getNodeOffileById(id) {
				if (id.length > 0) {
					console.log(id[0]);
					let data = (await File.api().get(`${apiUrl}/device/filesystem/get/node/by/id/${id[0]}`)).response.data;
					console.log(data);
					this.showFile(data);
				} else {
					this.showingFile = false;
				}
			},
			async getChatOfFile(filename) {
				let urlChatMessage = `${this.apiUrl}/device/chat/filename/${filename}`;
				await axios.get(urlChatMessage).then(res => {
					console.log("res: ", res.data);
					this.actualChatData = res.data;
				});
			},
			async showFile(node) {
				if (!node || node.length == 0) return;
				this.fileToShow = null;
				console.log("SHOW FILE: ", node);
				if (typeof node === 'object' || typeof node === undefined) {
					this.fileScaricati.data.map(elem => {
						console.log(elem.id, "==", node[0])
						if (elem.id == node[0]) {
							node = elem;
							console.log("node: ", node);
						}
					});
					if (node[0]) {
						return;
					}
				}
				this.fileToShow = node;
				var fileName = 'downloadfile/' + this.fileToShow.name;
				this.getFile(fileName);
				this.videoKey = (new Date()).getTime();
				this.getChatOfFile(node.name);
				let dataRelevance = await SavedRelevance.getDataRelevance(node.id);
				this.relevanceIsCreated = typeof dataRelevance === "object" ? false : true;
				if (node.extension === 'doc-text') {
					this.convertDocxToPdfFromUrl();
				}
				this.showingFile = true;
			},
			async getFile(fileName) {
				try {
					this.nameFile = apiUrl + "/" + fileName + "?token=" + this.token + '&deviceid=' + this.deviceId;
				} catch (e) {
					console.log(e);
					this.nameFile = "";
				}
			},
			removeLeaf(node) {
				if (node.is_dir) {
					let nullChild = 0;
					if (node != null) {
						for (let i = 0; i < node.children.length; i++) {
							if (node.children[i].is_dir)
								this.removeLeaf(node.children[i])
							else {
								delete node.children[i];
								nullChild++
							}
						}
						if (nullChild === node.children.length)
							delete node.children;
					}
				}
				return node
			},
			getParents(node) {
				if (node && node.parentId) {
					const parent = File.find(node.parentId);
					return [...this.getParents(parent), { id: parent ? parent.id : null, name: parent ? parent.name : '' }];
				}
				else return [];
			},
			downloadFile(node, index = null) {
				console.log("index: ", index)
				if (index != null) {
					this.indexDownloading = index;
				}
				let size = this.formatBytesToMegabytes(node.size);
				if (size[1] != 'Bytes' && size[1] != 'KB') {
					if ((
						size[1] == 'MB' && size[0] > 100) ||
						(
							size[1] == 'GB' ||
							size[1] == 'TB' ||
							size[1] == 'PB' ||
							size[1] == 'EB' ||
							size[1] == 'ZB' ||
							size[1] == 'YB'
						)
					) {
						Notification.insert({
							data: {
								icon: 'mdi-progress-upload',
								color: 'red darken-1',
								title: `Il file è troppo grande!`,
								subtitle: 'Per motivi di sicurezza non è possibile scaricare un file di queste dimensioni!',
								actionLabel: 'OK',
								actionIcon: 'mdi-check',
								deviceId: sessionStorage.getItem("deviceId"),
							}
						});
						return;
					}
				}
				File.dispatch(DOWNLOAD_FILE,
					{
						fileId: node.id,
						file: node.remote_path + '/' + node.name,
						user: this.user,
						device: this.device,
						functionToReloadData: this.getFileSystemByActiveNode,
					}).then(err => {
						console.log("ERR: ", err);
					})
			},
			async refreshDir() {
				let splitted = "";
				if (this.filesystem[0] != null && this.filesystem[0] != undefined) {
					splitted = this.filesystem[0].remote_path;
				}
				else {
					splitted = this.actualFolder;
				}
				await File.dispatch(REFRESH_DIRECTORY_COMMAND,
					{
						file: splitted,
						device: this.device,
						user: this.user,
						functionToReloadData: this.getFileSystemByActiveNode,
					},
				);

			},
			async getFileSystemByActiveNode() {
				this.filesystem = (await File.api().get(`${apiUrl}/device/filesystem?root=0&parentId=${this.activeNodeId}&timeEpoch=${this.timeEpoch}`)).response.data;
				this.activeNodeId = this.filesystem[0].parentId;
				this.indexDownloading = null;
				this.downloadedFileFunc();
			},
			async loadFolderChildren(item) {
				return File.api().get(`${apiUrl}/device/filesystem?root=0&parentId=${item.id}&timeEpoch=${this.timeEpoch}`)
					.then((result) => {
						let data = result.response.data;

						if (data.length == 0) {
							Vue.set(item, 'children', [{ name: "Nessun dato", id: -10 }]);
						} else {
							var formattedData = data
								.map(elem => {
									if (elem.children == null && elem.is_dir) {
										elem.children = [];
									}
									return elem;
								});
							Vue.set(item, 'children', formattedData);
						}
					})
					.catch((e) => console.log(e));
			},
			async downloadedFileFunc() {
				console.log("DOWNLOAD FILE");
				if (this.textToFilter != "") {
					this.fileScaricati =
						(await File.api()
							.get(`${apiUrl}/device/filesystem/get-by-filter/${this.textToFilter}`)
						).response.data;
				} else {
					this.fileScaricati = (await File.api().get(`${apiUrl}/device/filesystem/get/download/file/${this.pageFileScaricati}`)).response.data;
				}

				this.downloadedFile = [
					{
						id: 1,
						name: 'Scaricati',
						children: this.fileScaricati.data
					}
				];
			}
		},
		computed: {
			zeroOpen: {
				get: function () {
					return [1, ...this.open];
				},
				set: function (newValue) { }
			},
			filter() {
				return (item, search, textKey) => {
					return item ? item[textKey].indexOf(search) > -1 : false;
				}
			},
			generateRapidPath() {
				return [
					{
						id: 2,
						name: 'WhatsApp Images'
					},
					{
						id: 3,
						name: 'WhatsApp Video'
					},
					{
						id: 7,
						name: 'WhatsApp Voice Notes'
					},
					{
						id: 4,
						name: 'WhatsApp Video Notes'
					},
					{
						id: 5,
						name: 'Download'
					},
					{
						id: 6,
						name: 'Documents'
					},
					{
						id: 1,
						name: 'Camera'
					},
				];
			},
			device() {
				return Device.query().with(['state']).find(sessionStorage.getItem("deviceId"))
			},
			user() {
				return this.$store.getters.currentUser
			},
		},
		async created() {
			this.initFileSystem();
		},

	}
</script>
<style scoped>
	.tooltip-container {
		position: relative;
		display: inline-block;
		cursor: pointer;
	}

	.btn-cont {
		display: flex;
		width: auto;
	}

	.tooltip {
		visibility: hidden;
		width: 124px;
		height: auto;
		font-size: 9px;
		text-align: center !important;
		z-index: 1;
		background-color: #000;
		color: #fff;
		text-align: center;
		border-radius: 4px;
		padding: 6px;
		text-align: center;
		position: absolute;
		top: 10px;
		margin-bottom: 24px;
		left: 50%;
		transform: translateX(-50%);
		opacity: 0;
		transition: opacity 0.3s ease;
	}

	.tooltip-container:hover .tooltip {
		visibility: visible;
		opacity: 1;
	}
</style>