export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const PURGE_AUTH = 'purgeAuth';

export const SAVE_DEVICES = 'saveDevices';
export const SET_DEVICE = 'setDevice';
export const SET_AUTHENTICATE = 'setAuthenticate';
export const RESET_DEVICE = 'resetDevice';
export const RESET_SESSION = 'resetSession';
export const RESTART_DEVICE = 'restartDevice';
export const SET_DEVICE_CALL_REGISTER = 'setDeviceCallRegister';

export const OPENED_SOCKET = 'openedSocket';
export const CLOSED_SOCKET = 'closedSocket';
export const RECONNECT_SOCKET = 'reconnectedSocket';
export const ERROR_SOCKET = 'errorSocket';
export const SAVE_SOCKET_BUFFER = 'saveSocketBuffer';
export const PUSH_TO_BUFFER = 'pushToBuffer';
export const SOCKET_AUTHENTICATED = 'socketAuthenticated';
export const SUBSCRIBE_SOCKET_EVENT = 'subscribeSocketEvent';