<template>
	<v-container class="pa-0">

		<v-toolbar>
			Dashboard {{device.manufacturer}} {{device.name}}
		</v-toolbar>

		<v-row class="d-flex align-center justify-center">
			<v-col cols="5" align-self="start">
				<device-info class="mt-4" :device="device" />
				<device-console :device="device" :user="user" />
				<device-permission :device="device" v-if="getUsername == 'admin'" />
			</v-col>
			<v-col cols="5" align-self="start">
				<v-card class="mt-4">
					<v-card-title>Stato della batteria</v-card-title>
					<v-card-text>
						<v-sparkline :value="batteryValues"
												 :smooth="12"
												 :gradient="['#f72047', '#ffd200', '#1feaea'].reverse()"
												 :line-width="2"
												 :fill="false"
												 auto-draw></v-sparkline>
						<v-list three-line>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Consumo massimo raccomandato</v-list-item-title>
									<v-list-item-subtitle>Superato questo limite il telefono potrebbe notificare all'utente che un'applicazione sta effettuando operazioni in background, creando un campanello d'allarme</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									6%/h
								</v-list-item-action>
							</v-list-item>

							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Consumo attuale</v-list-item-title>
									<v-list-item-subtitle>Questo dato indica la percentuale di batteria consumata nell'arco dell'ultima ora</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									3%/h
								</v-list-item-action>
							</v-list-item>
						</v-list>
					</v-card-text>
				</v-card>

				<v-card class="mt-4">
					<v-card-title>Consumo dati</v-card-title>
					<v-card-text>
						<v-sparkline :value="trafficValues"
												 :smooth="12"
												 :gradient="['#f72047', '#ffd200', '#1feaea']"
												 :line-width="2"
												 :fill="false"
												 auto-draw></v-sparkline>
						<v-divider></v-divider>
						<v-list three-line>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Consumo massimo raccomandato</v-list-item-title>
									<v-list-item-subtitle>Superato questo limite il telefono potrebbe notificare all'utente che un'applicazione sta effettuando operazioni in background, creando un campanello d'allarme</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									50MB
								</v-list-item-action>
							</v-list-item>

							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Consumo attuale</v-list-item-title>
									<v-list-item-subtitle>Questo dato indica i dati inviati e ricevuti dal telefono nell'arco della giornata</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									{{trafficValues[trafficValues.length-1]*1024 | fileSize}}
								</v-list-item-action>
							</v-list-item>
						</v-list>
					</v-card-text>
				</v-card>

				<v-card class="mt-4">
					<device-conf v-if="getUsername == 'admin'" />
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import DeviceInfo from "@/components/device/DeviceInfo";
	import DeviceConf from "@/components/device/DeviceConf";
	import Device from "@/store/models/device.model";
	import { DEVICE_INIT, FETCH_DEVICE } from "@/store/actions.type";
	import DeviceConsole from "@/components/device/DeviceConsole";
	import DevicePermission from "@/components/device/DevicePermission";
	import { mapGetters } from "vuex";

	export default {
		name: "Dashboard",
		components: {
			DeviceInfo,
			DeviceConsole,
			DevicePermission,
			DeviceConf
		},
		methods: {
			goTo(path) {
				this.$router.push(path);
			},
			logout() {
				this.$store.dispatch('logout').then(() => this.$router.push('/login'))
			}
		},
		computed: {
			...mapGetters(['isAuthenticated']),
			device() {
				return Device.query().with('state').with('batteryRegister').with('trafficRegister').find(sessionStorage.getItem("deviceId"))
			},
			getUsername() {
				return this.$store.getters.currentUser.username
			},
			user() {
				return this.$store.getters.currentUser
			},
			isFetching() {
				return Device.getters('isFetching')
			},
			batteryValues() {
				let vals = this.device.batteryRegister.map(x => x.level)
				return [100, 0].concat(vals);
			},
			trafficValues() {
				let sum = 0;
				let vals = this.device.trafficRegister.map(x => {
					sum += x.value
					return sum;
				})
				return [50000, 0].concat(vals)
			}
		},
		created() {
			Device.dispatch(DEVICE_INIT, { id: sessionStorage.getItem("deviceId") })
			Device.dispatch(FETCH_DEVICE, { id: sessionStorage.getItem("deviceId") })
			const appvue = this.$root.$children[0];
			appvue.getNumberOfNotReadNotification();
		},
	}
</script>

<style scoped>
</style>