<script>
	import axios from '../../axios';
	import { apiUrl } from "@/common/endpoint.const";

	export default {
		name: 'NotificationPage',
		data() {
			return {
				currentPage: 1,
				pageSize: 8,
				totalNotifications: 0,
				notifications: [],
				notyNumberNotRead: 0,
				filterDateFrom: null,
				filterDateTo: null,
				selectedType: null,
				notyType: ["Chat", "Rilevanze", "Tutte"],
				read: ["Lette", "Non Lette", "Tutte"],
				readSelected: null,
			}
		},
		watch: {
			currentPage: 'fetchNotifications',
			pageSize: 'fetchNotifications',
			filterDateFrom: 'fetchNotifications',
			filterDateTo: 'fetchNotifications',
			selectedType: 'fetchNotifications',
			readSelected: 'fetchNotifications',
		},
		methods: {
			async fetchNotifications() {
				try {
					const response = await axios.post(`${apiUrl}/device/noty/paginate`, {
						page: this.currentPage,
						limit: this.pageSize,
						filter: {
							dallaData: this.filterDateFrom,
							allaData: this.filterDateTo,
							type: this.selectedType == "Chat" ? "chat" : this.selectedType == "Rilevanze" ? "relevance" : null,
							onlyRead: this.readSelected == "Lette" ? true : this.readSelected == "Non Lette" ? false : null
						}
					});
					console.log(response);
					let data = response.data;
					this.notifications = data.response;
					this.totalNotifications = data.count;
				} catch (error) {
					console.error('Errore nel recupero delle notifiche:', error);
				}
			},
			resetFilterData() {
				this.filterDateFrom = null;
				this.filterDateTo = null;
			},
			async viewNoty(noty) {
				if (noty.read == false) {
					await axios.get(`${apiUrl}/device/noty/read?notificationId=${noty.id}`);
					noty.read = true;
				}
				await this.getNumberOfNotReadNotification();
				console.log("noty: ", noty);
				let teleportUrl = `/device/dashboard`;
				if (window.location.href.includes('dashboard')) {
					teleportUrl = `/device/call-register`;
				}
				else if (window.location.href.includes('call-register')) {
					teleportUrl = `/device/dashboard`;
				}
				switch (noty.type) {
					case 'chat':
						if (noty.id_chat_message != null)

							this.$router.push(teleportUrl).then(() => {
								this.$router.push(`/device/chats?idMsg=${noty.id_chat_message}`);
							});

						break;
					case 'relevance':
						if (noty.id_relevance != null)

							this.$router.push(teleportUrl).then(() => {
								this.$router.push(`/device/relevance/${noty.id_relevance}`).catch(err => {
									if (err.name !== 'NavigationDuplicated') {
										throw err;
									};
								});
							});

						break;
				}
			},
			async getNumberOfNotReadNotification() {
				try {
					const response = await axios.get(`${apiUrl}/device/noty/number-not-read`);
					console.log("getNumberOfNotReadNotification: ", response.data);
					this.notyNumberNotRead = response.data;
				} catch (error) {
					console.error('Errore nel recupero delle notifiche:', error);
				}
			},
		},
		async created() {
			this.$store.state.notificationData.subscribe(async (data) => {
				await this.fetchNotifications();
				await this.getNumberOfNotReadNotification();
			});
		},
	}
</script>

<template>
	<v-row align="center" class="pa-4">
		<v-col cols="12" align-self="start">
			<v-card>
				<v-card-title>
					<v-icon class="ma-1">mdi-filter</v-icon>
					Filtra le notifiche
					<v-spacer></v-spacer>
				</v-card-title>
				<v-card-text>
					<div style="display:flex;align-items: center;">
						<v-menu :close-on-content-click="false"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="filterDateFrom"
															label="Dalla data"
															prepend-icon="mdi-calendar"
															readonly
															v-on="on" />
							</template>
							<v-date-picker v-model="filterDateFrom" @input="menu = false" />
						</v-menu>
						<v-menu :close-on-content-click="false"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="290px">
							<template v-slot:activator="{ on }">
								<v-text-field v-model="filterDateTo"
															label="Alla data"
															prepend-icon="mdi-calendar"
															readonly
															v-on="on" />
							</template>
							<v-date-picker v-model="filterDateTo" @input="menu = false" />
						</v-menu>
						<div class="tooltip">
							<v-btn color="green" block @click="resetFilterData()">
								<v-icon style="font-size: 20px;">mdi-delete</v-icon>
							</v-btn>
							<span class="tooltiptext">Resetta i filtri date</span>
						</div>
					</div>
					<div style="display:flex;align-items: center;">
						<v-select v-model="selectedType"
											:items="notyType"
											label="Seleziona la tipologia di notifica">
						</v-select>
						<v-select v-model="readSelected"
											:items="read"
											label="Lette o non lette">
						</v-select>
					</div>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" align-self="start">
			<v-card>
				<v-card-title>
					<v-icon class="ma-1">mdi-bell</v-icon>
					Notifiche Ricevute
					<v-spacer></v-spacer>
				</v-card-title>
				<v-virtual-scroll :items="notifications"
													item-height="80"
													height="640">
					<template v-slot:default="{ item }">
						<v-divider :key="'d'+item.id">
						</v-divider>

						<v-list-item :key="item.id" :class="item.read ? 'list-item-elem' : 'list-item-elem not_read_elem'" @click="viewNoty(item)">
							<div class="not-circle__border">
								<v-icon color="white" v-if="item.type == 'chat'">mdi-chat</v-icon>
								<v-icon color="white" style="font-size: 40px;" v-else>mdi-record-rec</v-icon>
							</div>
							<v-list-item-content>
								<v-list-item-title>
									{{item.title}}
								</v-list-item-title>
								<v-list-item-subtitle>
									{{item.description}}
								</v-list-item-subtitle>
								<v-list-item-subtitle>
									<span>
										{{ new Date(item.created_at).toLocaleString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) }}
									</span>
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</template>
				</v-virtual-scroll>
				<v-pagination v-model="currentPage"
											:length="Math.ceil(totalNotifications / pageSize)"
											@input="page => currentPage = page"
											:total-visible="5">
				</v-pagination>
			</v-card>
		</v-col>
	</v-row>
</template>

<style>
	.list-item-elem {
		cursor: pointer;
		transition: 2s;
	}

		.list-item-elem:hover {
			background-color: #3a3a3a;
		}

	.not_read_elem {
		background-color: #4a4a4a;
	}

		.not_read_elem::after {
			content: "•";
			display: inline-block;
			margin-right: 10px;
			font-size: 32px;
			color: #ff7a7a;
		}
</style>