<script>
	import axios from '../axios';
	import { apiUrl } from "@/common/endpoint.const";

	export default {
		name: 'NotificationBox',
		props: ['getNotReadCount'],
		data() {
			return {
				currentPage: 1,
				pageSize: 3,
				totalNotifications: 0,
				notifications: []
			}
		},
		computed: {
			async notificationData() {
				let data = JSON.parse(this.$store.state.notificationData);
				console.log("notificationData", data);
				await this.getNotReadCount();
				return data;
			}
		},
		watch: {
			async notificationData(newVal, oldVal) {
				console.log("notificationData", newVal);
				await this.fetchNotifications();
				await this.getNotReadCount();
			}
		},
		methods: {
			async fetchNotifications() {
				try {
					const response = await axios.post(`${apiUrl}/device/noty/paginate`, {
						page: this.currentPage,
						limit: this.pageSize,
						filter: {
							onlyRead: false
						}
					});
					console.log(response);
					let data = response.data;
					this.notifications = data.response;
					this.totalNotifications = data.count;
				} catch (error) {
					console.error('Errore nel recupero delle notifiche:', error);
				}
			},
			async readAllNotifications() {
				try {
					await axios.get(`${apiUrl}/device/noty/read-all`);
					await this.fetchNotifications();
					await this.getNotReadCount();
				} catch (error) {
					console.error('Errore nel recupero delle notifiche:', error);
				}
			},
			async viewNoty(noty) {
				if (noty.read == false) {
					await axios.get(`${apiUrl}/device/noty/read?notificationId=${noty.id}`);
					noty.read = true;
				}
				await this.fetchNotifications();
				await this.getNotReadCount();
				const url = window.location.href;
				let tp = `/device/dashboard`;
				if (url.includes("/dashboard")) {
					tp = `/device/call-register`;
				}
				switch (noty.type) {
					case 'chat':
						if (noty.id_chat_message != null)

							this.$router.push(tp).then(() => {
								this.$router.push(`/device/chats?idMsg=${noty.id_chat_message}`);
							});

						break;
					case 'relevance':
						if (noty.id_relevance != null)

							this.$router.push(tp).then(() => {
								this.$router.push(`/device/relevance/${noty.id_relevance}`).catch(err => {
									if (err.name !== 'NavigationDuplicated') {
										throw err;
									};
								});
							});

						break;
				}
			}
		},
		watch: {
			currentPage: 'fetchNotifications',
			pageSize: 'fetchNotifications',
		},
		async mounted() {
			await this.fetchNotifications(); // Carica le notifiche quando il componente viene montato
		},
		async created() {
			this.$store.state.notificationData.subscribe(async (data) => {
				await this.fetchNotifications();
				await this.getNotReadCount();
			});
		},
	}
</script>

<template>

	<div class="view-box" id="noty-view">
		<template v-if="notifications.length > 0">
			<div class="title-container">
				<h3 class="noty-title">Notifiche</h3>
				<v-btn @click="readAllNotifications" class="purple-button float-end">Leggi tutto</v-btn>
			</div>
			<div class="body d-flex align-items-center">
				<ul class="list-noty">
					<li v-for="noty in notifications" :key="noty.id" :class="noty.read ? '' : 'not_read'" @click="viewNoty(noty)">
						<div class="not-circle__border">
							<v-icon color="black" v-if="noty.type == 'chat'">mdi-chat</v-icon>
							<v-icon color="black" style="font-size: 40px;" v-else>mdi-record-rec</v-icon>
						</div>
						<div class="d-flex align-items-end w-100">
							<div class="d-flex flex-column">
								<h5>{{noty.title}}</h5>
								<p class="text-noty">{{noty.description}}</p>
							</div>
							<span class="time">{{ new Date(noty.created_at).toLocaleString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) }}</span>
						</div>
					</li>
				</ul>
			</div>
			<v-pagination v-model="currentPage"
										:length="Math.ceil(totalNotifications / pageSize)"
										@input="page => currentPage = page"
										:total-visible="5">
			</v-pagination>
		</template>
		<template v-else>
			<div class="body d-flex align-items-center margin-none container-no-noty">
				<p class="not-notify margin-none">Nessuna notifica</p>
			</div>
		</template>
	</div>
</template>

<style>
	.not-notify {
		margin: 0;
		color: #666;
		font-size: 14px;
	}

	.margin-none {
		margin: 0 !important;
	}

	.title-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		border-bottom: 1px solid #eee;
	}

	.noty-title {
		margin: 0;
		color: #333;
	}

	.view-box {
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0 4px 6px rgba(0,0,0,0.1);
		overflow: hidden;
	}

	.v-pagination {
		height: 60px;
		margin-top: -25px;
		overflow: hidden;
	}

	.v-pagination__more {
		background: #1E1E1E;
		color: white;
		border-radius: 3px;
	}

	.not_read::after {
		content: "•";
		display: inline-block;
		margin-right: 10px;
		font-size: 32px;
		color: #ff7a7a;
	}

	.not_read h5, .not_read p {
		font-weight: bold;
		text-decoration: underline;
	}

	.not_read {
		background-color: #e4e4e4;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	}

	.body {
		margin-bottom: 20px;
	}

	.list-noty {
		list-style: none;
		padding: 0;
		height: 280px;
		overflow: auto;
	}

		.list-noty li {
			border-bottom: 1px solid #eee;
			padding: 15px;
			display: flex;
			align-items: center;
			cursor: pointer;
			transition: background-color 0.3s;
		}

	.container-no-noty {
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 10px;
		font-weight: bold;
	}

	.list-noty li:hover {
		background-color: #f5f5f5;
	}

	.not-circle__border {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 15px;
		;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.icon_elem {
		font-size: 24px;
		color: #000000;
	}

	.not-circle__border img {
		width: 100%;
		height: auto;
	}

	.d-flex {
		display: flex;
		align-items: center;
	}

	.align-items-center {
		align-items: center;
	}

	.v-application ul, .v-application ol {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	.align-items-end {
		align-items: flex-end;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	h5 {
		margin: 0;
		color: #333;
		font-size: 16px;
		width: 100%;
	}

	.text-noty {
		margin: 0;
		color: #666;
		font-size: 14px;
	}

	.time {
		margin-left: auto;
		color: #999;
		font-size: 12px;
	}

	.btn-primary {
		background-color: #007bff;
		border-color: #007bff;
	}

	.purple-button {
		background-color: #6f42c1;
		border-color: #6f42c1;
		padding: 10px;
		border-radius: 10px;
	}

	.float-end {
		float: right;
	}

	.w-100 {
		width: 100%;
	}
</style>
