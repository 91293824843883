const TOKEN_ID = 'token';

export const getToken = () => {
	return window.sessionStorage.getItem(TOKEN_ID)
}

export const saveToken = (token) => {
	window.sessionStorage.setItem(TOKEN_ID, token);
	sessionStorage.setItem(TOKEN_ID, token);
}

export const destroyToken = () => {
	window.sessionStorage.removeItem(TOKEN_ID);
}

export default { getToken, saveToken, destroyToken }