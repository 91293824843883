<template>
	<div v-if="device">
		<v-row align="center" class="pa-4">
			<!--<v-col cols="3" align-self="start">
				<device-info :device="device"></device-info>
			</v-col>-->

			<v-col cols="5" align-self="start">

				<v-card>
					<div style="    display: flex;justify-content: space-between;align-items: center;">
						<v-card-title>
							Rubrica
						</v-card-title>
						<v-btn icon @click="refreshContact()">
							<v-icon color="primary">
								mdi-refresh
							</v-icon>
						</v-btn>
					</div>
					<v-card-text>
						<v-list>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										Contatti salvati in rubrica
									</v-list-item-title>
									<v-list-item-subtitle>
										Include tutti i contatti salvati in rubrica,<br /> tramite le app di chat e i contatti sconosciuti
									</v-list-item-subtitle>
								</v-list-item-content>

								<v-list-item-action>
									{{device.contactList.length}}
								</v-list-item-action>
							</v-list-item>
							<v-divider inset></v-divider>
						</v-list>
					</v-card-text>
				</v-card>

				<v-card>
					<v-card-title>
						<v-row dense>
							<v-col cols="6">
								<v-text-field v-model="searchField"
															solo
															outlined
															append-icon="mdi-magnify"
															placeholder="Ricerca per nome"></v-text-field>
							</v-col>
							<v-col cols="3">
								<v-select v-model="orderField"
													:items="orderFields"
													label="ordina per"
													return-object
													item-text="label"
													item-value="value"></v-select>
							</v-col>
							<v-col cols="3">
								<v-select v-model="orderType"
													:items="orderTypes"
													label="ordinamento"
													return-object
													item-text="label"
													item-value="value"></v-select>
							</v-col>
						</v-row>
					</v-card-title>
					<v-btn :loading="exportWordLoading"
								 @click="exportAll()"
								 style="width: 100%;"
								 color="primary">
						<v-icon>mdi-export</v-icon>Export all
					</v-btn>
					<v-virtual-scroll :items="sortedList"
														height="600"
														item-height="64">
						<template v-slot:default="{ item }">
							<v-divider :key="'d'+item.id"></v-divider>

							<v-list-item :key="item.id" @click="selectContact(item)">
								<v-list-item-avatar>
									<avatar :username="item.name"></avatar>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title>
										{{ item.known ? item.name : `${item.name} - (sconosciuto)` }}
									</v-list-item-title>
									<v-list-item-subtitle>
										{{ item.phone }}
									</v-list-item-subtitle>
								</v-list-item-content>

								<v-list-item-action>
									<v-btn icon>
										<v-icon color="grey lighten-1">mdi-information</v-icon>
									</v-btn>
								</v-list-item-action>
							</v-list-item>
						</template>
					</v-virtual-scroll>
				</v-card>
			</v-col>

			<v-col cols="4" v-if="selectedContact" align-self="start">
				<v-card>
					<v-subheader>Dettagli contatto</v-subheader>
					<v-divider></v-divider>
					<v-list>
						<v-list-item>
							<v-row>
								<v-col cols="6">Nome</v-col>
								<v-col cols="6" class="text-caption">
									<span>
										{{selectedContact.name}}
									</span>
								</v-col>
							</v-row>
						</v-list-item>

						<v-list-item>
							<v-row>
								<v-col cols="6">Numero di telefono</v-col>
								<v-col cols="6" class="text-caption">
									<span>
										{{selectedContact.phone}}
									</span>
								</v-col>
							</v-row>
						</v-list-item>
					</v-list>
				</v-card>

				<v-card>
					<v-subheader>Attività contatto</v-subheader>
					<v-divider></v-divider>
					<contact-activity-info :contact="selectedContact"></contact-activity-info>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import ContactActivityInfo from "@/components/contact/ContactActivityInfo";
	import DeviceInfo from "@/components/device/DeviceInfo";
	import Avatar from 'vue-avatar';
	import Console from "@/store/models/console.model";
	import Device from "@/store/models/device.model";
	import {
		DEVICE_CONTACT_LIST_SORT,
		FETCH_DEVICE_CONTACT_REGISTER
	} from "@/store/actions.type";
	import {
		Document,
		Packer,
		Paragraph,
		TextRun,
		ImageRun,
		AlignmentType,
		TableCell,
		TableRow,
		Table,
	} from "docx";
	import Swal from "sweetalert2";

	export default {
		name: "DeviceContactList",
		components: {
			ContactActivityInfo,
			DeviceInfo,
			Avatar
		},
		data() {
			return {
				selectedContact: null,
				searchField: '',
				console: null,
				orderField: { label: 'Nome', value: 'name' },
				orderType: { label: 'Crescente', value: 'asc' },
				orderTypes: [
					{ label: 'Crescente', value: 'asc' },
					{ label: 'Decrescente', value: 'desc' },
				],
				orderFields: [
					{ label: 'Nome', value: 'name' },
					{ label: 'Data Creazione', value: 'created_at' },
					{ label: 'Messaggi ricevuti', value: 'total_received_messages' },
					{ label: 'Messaggi inviati', value: 'total_sent_messages' },
					{ label: 'Chiamate ricevute', value: 'total_received_calls' },
					{ label: 'Chiamate effettuate', value: 'total_sent_calls' },
				],
				exportWordLoading: false,
			}
		},
		methods: {
			selectContact(contact) {
				this.selectedContact = contact;
			},
			async refreshContact() {
				await this.console.sendCommand({ cmd: "CONTACT", params: [], user: JSON.parse(sessionStorage.getItem('user')) || {} }, sessionStorage.getItem("deviceId"));
			},
			async exportAll() {
				const contactList = this.device.contactList;

				this.exportWordLoading = true;
				try {
					var exportDocumentInfos = (await axios.get(`${apiUrl}/device/get-document-export`)).data;
					const documentLogo = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new ImageRun({
								data: window.config.reportImageLogo,
								transformation: {
									width: 100,
									height: 100,
								},
								alignment: AlignmentType.CENTER,
							}),
						],
					});
					const documentTitle = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: exportDocumentInfos.titolo,
								bold: true,
								color: '000000',
								size: 36,
							}),
						]
					});
					const documentGroup = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: exportDocumentInfos.gruppo,
								bold: true,
								color: '000000',
								size: 24,
							}),
						]
					});
					const documentOperativeCore = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: exportDocumentInfos.tipo_nucleo,
								color: '000000',
								size: 19,
							}),
						]
					});
					const documentInfo = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: exportDocumentInfos.informazioni_varie,
								color: '000000',
								size: 20,
							}),
						]
					});
					const blankSpace = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: "",
								color: '000000',
								size: 20,
							}),
						]
					});
					const imageDivider = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new ImageRun({
								data: window.config.reportImageDivider,
								transformation: {
									width: 100,
									height: 50,
								},
								alignment: AlignmentType.CENTER,
							}),
						],
					});
					const deviceParagraph = [];
					const deviceParagraphTitle = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: "ESPORTAZIONE LISTA CONTATTI",
								bold: true,
								color: '000000',
								size: 24,
							}),
						]
					});
					const deviceTableHeader = new TableRow({
						children: [
							new TableCell({
								shading: {
									fill: "D9D9D9",
								},
								children: [
									new Paragraph({
										children: [
											new TextRun({
												text: "Data e ora",
												bold: true,
												color: '000000',
												size: 20,
											}),
										],
									})
								]
							}),
							new TableCell({
								shading: {
									fill: "D9D9D9",
								},
								children: [
									new Paragraph({
										children: [
											new TextRun({
												text: "Bersaglio",
												bold: true,
												color: '000000',
												size: 20,
											}),
										],
									})
								]
							}),
							new TableCell({
								shading: {
									fill: "D9D9D9",
								},
								children: [
									new Paragraph({
										children: [
											new TextRun({
												text: "P.P - RIT",
												bold: true,
												color: '000000',
												size: 20,
											}),
										],
									})
								]
							})
						]
					});
					const nowDate = new Date();
					const now =
						nowDate.toLocaleDateString(
							'it-IT', {
							day: '2-digit',
							month: '2-digit',
							year: 'numeric'
						}) +
						' ' +
						nowDate.toLocaleTimeString(
							'it-IT', {
							hour: '2-digit',
							minute: '2-digit'
						});
					const deviceTableContent = new TableRow({
						children: [
							new TableCell({
								children: [
									new Paragraph({
										children: [
											new TextRun({
												// text:today.getDate()+"/"+today.getMonth()+1+"/"+today.getFullYear()+" "+today.getHours()+":"+today.getMinutes(),
												text: now,
												color: '000000',
												size: 20,
											}),
										],
									}),
								],
							}),
							new TableCell({
								children: [
									new Paragraph({
										children: [
											new TextRun({
												text: 'IMEI:' + this.device.imei1,
												color: '000000',
												size: 20,
											}),
										],
									}),
								],
							}),
							new TableCell({
								children: [
									new Paragraph({
										children: [
											new TextRun({
												text: exportDocumentInfos.rit_acquisizioni,
												color: '000000',
												size: 20,
											}),
										],
									}),
								],
							}),
						]
					});
					const deviceTable = new Table({
						alignment: AlignmentType.CENTER,
						columnWidths: [2800, 2800, 2800],
						rows: [
							deviceTableHeader,
							deviceTableContent
						]
					});
					deviceParagraph.push(
						deviceParagraphTitle,
						blankSpace,
						deviceTable,
					);
					const contactsParagraph = [];
					const contactsParagraphTitle = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: "LISTA CONTATTI",
								bold: true,
								color: '000000',
								size: 24,
							}),
						]
					});
					const contactTableHeader = new TableRow({
						children: [
							new TableCell({
								shading: {
									fill: "D9D9D9",
								},
								children: [
									new Paragraph({
										children: [
											new TextRun({
												text: "Nome",
												bold: true,
												color: '000000',
												size: 20,
											}),
										],
									})
								]
							}),
							new TableCell({
								shading: {
									fill: "D9D9D9",
								},
								children: [
									new Paragraph({
										children: [
											new TextRun({
												text: "Numero di telefono",
												bold: true,
												color: '000000',
												size: 20,
											}),
										],
									})
								]
							})
						]
					})
					const contactsTableContent = [];
					contactList.map((contact) => {
						contactsTableContent.push(
							new TableRow({
								children: [
									new TableCell({
										children: [
											new Paragraph({
												children: [new TextRun(
													contact.name
												)],
											})
										]
									}),
									new TableCell({
										children: [
											new Paragraph({
												children: [new TextRun(
													contact.phone
												)],
											})
										]
									}),
								]
							})
						)
					});
					const contactTable = new Table({
						alignment: AlignmentType.CENTER,
						columnWidths: [4200, 4200],
						rows: [
							contactTableHeader,
							...contactsTableContent,
						],
					})
					contactsParagraph.push(
						contactsParagraphTitle,
						blankSpace,
						contactTable,
					);
					const firmaText = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: exportDocumentInfos.titolo_firma,
								size: 25,
								color: '000000',
							}),
						],
					});
					const linea = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: '____________________',
								bold: true,
								size: 20,
								color: '000000',
							}),
						],
					});
					const firmatario = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: exportDocumentInfos.firmatario,
								size: 18,
								color: '000000',
							}),
						],
					});

					const doc = new Document({
						sections: [{
							properties: {},
							children: [
								documentLogo,
								documentTitle,
								documentGroup,
								documentOperativeCore,
								documentInfo,
								imageDivider,
								...deviceParagraph,
								blankSpace,
								...contactsParagraph,
								blankSpace,
								blankSpace,
								blankSpace,
								firmaText,
								blankSpace,
								linea,
								firmatario
							],
						}]
					});

					const timestamp = new Date().toISOString();
					Packer.toBlob(doc).then(blob => {
						saveAs(blob, 'ListaContatti' + "-" + timestamp + ".docx");
					});
				} catch (error) {
					console.log(error);
					Swal.fire({
						title: "Errore!",
						text: "Si è verificato un errore durante l'esportazione dei dati!",
						icon: "error",
						confirmButtonText: "Ok"
					});
				} finally {
					this.exportWordLoading = false;
				}
			},
		},
		computed: {
			device() {
				return Device.query()
					.with('state')
					.with('contactList', (query) => query.orderBy('name', 'asc'))
					.find(sessionStorage.getItem("deviceId"))
			},
			sortedList() {
				return Device.query()
					.with('state')
					.with('contactList', (query) => {
						query.orderBy(this.orderField.value, this.orderType.value)
						query.where('name', (value) => value.toLowerCase().includes(this.searchField.toLowerCase()))
						query.orWhere('phone', (value) => value.includes(this.searchField))
					})
					.find(sessionStorage.getItem("deviceId")).contactList
			}
		},
		async created() {
			Device.dispatch(FETCH_DEVICE_CONTACT_REGISTER, sessionStorage.getItem("deviceId"));
			if (!this.console) {
				console.log('[CREATING CONSOLE...]')
				await Console.insertOrUpdate({
					data: {
						deviceId: sessionStorage.getItem("deviceId"),
						device: this.device,
					}
				}).then((data) => {
					this.console = data.console[0];
				});
			}
		}
	}
</script>

<style scoped>
</style>