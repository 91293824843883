<template>
	<v-container>
		<h1>
			Lista applicazioni
			<v-btn :disabled="loading"
						 @click="commandUpdateAppsList()">
				<v-icon v-if="!loading" title="Aggiorna">mdi-refresh</v-icon>
				<v-icon v-else>mdi-loading</v-icon>
			</v-btn>
		</h1>
		<v-card class="list-container">
			<v-card v-for="(application, index) in applications" :key="index">
				<v-card-title><v-icon>mdi-android</v-icon>{{ application?.appName }}</v-card-title>
			</v-card>
		</v-card>
	</v-container>
</template>

<script>
	import { apiUrl } from "@/common/endpoint.const";
	import axios from '../../axios';
	import Console from "@/store/models/console.model";

	export default {
		name: "DeviceApps",
		async created() {
			if (!this.console) {
				console.log("creating console...");
				await Console.insertOrUpdate({
					data: {
						deviceId: sessionStorage.getItem("deviceId"),
						device: this.device,
					},
				})
			}
			await this.getApplications();
		},
		data() {
			return {
				applications: [],
				loading: false,
			}
		},
		computed: {
			console() {
				return Console.query()
					.with("device")
					.with("cmdHistory")
					.where("deviceId", sessionStorage.getItem("deviceId"))
					.first();
			},
		},
		methods: {
			async commandUpdateAppsList() {
				this.loading = true;
				await this.console.sendCommand({
					cmd: "APP",
					params: [],
					user: JSON.parse(sessionStorage.getItem('user') || '') || {}
				},
					sessionStorage.getItem("deviceId")
				);

				await new Promise(resolve => setTimeout(resolve, 5000));
				await this.getApplications();
			},
			async getApplications() {
				await axios.get(`${apiUrl}/device/applications`)
					.then((response) => {
						this.applications = response.data;
					}).catch((error) => {
						console.log(error);
					});
				this.loading = false;
			}
		}
	}
</script>

<style>
	.list-container {
		gap: 10px;
		margin: 20px;
	}
</style>