<template>
	<div>
		<v-dialog v-model="dialog"
							width="1024"
							style="z-index: 9999 !important">
			<v-card>
				<!-- Inser a title -->
				<v-card-title>
					<span>Aggiungi evento alla seguente data {{ dateToShow }}</span>
					<v-spacer></v-spacer>
				</v-card-title>
				<v-card-text>
					<v-text-field v-model="eventDetails.event_name"
												label="Clicca qui per inserire il nome dell'evento"
												required>
					</v-text-field>
					<v-textarea v-model="eventDetails.description"
											placeholder="Clicca qui per inserire la descrizione dell'evento"
											solo
											outlined
											rows="5">
					</v-textarea>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue-darken-1"
								 variant="text"
								 @click="dialog = false">
						Close
					</v-btn>
					<v-btn color="blue-darken-1"
								 :disabled="!eventDetails.event_name || !eventDetails.description"
								 variant="text"
								 @click="addEvent()">
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-card>
			<v-card-title>
				<span>Player</span>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="openDialog()" :disabled="loading"><v-icon>mdi-flag</v-icon>Aggiungi evento</v-btn>
			</v-card-title>
			<v-card-text>
				<div id="waveform-container" class="ma-4">
					<div id="zoomview-container"></div>
					<v-divider></v-divider>
					<div id="overview-container"></div>
				</div>
				<div v-if="loading" style="height:300px !important; margin-top: -285px !important; z-index: 999" class="d-flex justify-center align-center">
					<v-progress-circular color="green" :indeterminate="true"></v-progress-circular>Caricamento traccia in corso...
				</div>
			</v-card-text>
			<v-card-actions class="container-controller">
				<div class="container-volume">
					<v-slider @input="volumeChange"
										v-model="volume"
										style="max-width: 150px;"
										:prepend-icon="isMute ? 'mdi-volume-off' : 'mdi-volume-high'"
										:disabled="loading" />
					<!-- create checkbox -->
					<v-checkbox v-model="enableTranscriptinStop"
											:disabled="loading"
											:label="'Auto stop'"
											:value="enableTranscriptinStop"
											:true-value="true"
											:false-value="false" />
					<!-- create number type input -->
					<v-text-field v-if="enableTranscriptinStop"
												class="v-text-field__input"
												v-model="stopDuration"
												:disabled="loading"
												:label="'Durata stop'"
												:type="'number'"
												:min="1" />
				</div>
				<div class="container-button__control">
					<v-btn color="primary" @click="zoomOut()" :disabled="loading" fab small><v-icon>mdi-magnify-minus</v-icon></v-btn>
					<v-btn color="primary" class="margin-left__5px" @click="back(5)" :disabled="loading" fab small><v-icon>mdi-skip-backward</v-icon></v-btn>
					<v-btn color="primary" class="margin-left__5px" @click="play()" v-if="!isPlaying" :disabled="loading" fab><v-icon>mdi-play</v-icon></v-btn>
					<v-btn color="primary" class="margin-left__5px" @click="pause()" v-else :disabled="loading" fab><v-icon>mdi-pause</v-icon></v-btn>
					<v-btn color="primary" class="margin-left__5px" @click="forward(5)" :disabled="loading" fab small><v-icon>mdi-skip-forward</v-icon></v-btn>
					<v-btn color="primary" class="margin-left__5px" @click="zoomIn()" :disabled="loading" fab small><v-icon>mdi-magnify-plus</v-icon></v-btn>
				</div>
				<span style="white-space: nowrap;">{{ updateData }}</span>
				<!-- <div class="d-flex">
					<v-btn color="primary" v-if="!isLooping" @click="startLoop()" :disabled="loading"><v-icon>mdi-sync</v-icon><v-icon>mdi-play</v-icon>Inizia loop</v-btn>
					<v-btn color="primary" v-else @click="endLoop()" :disabled="loading"><v-icon>mdi-sync</v-icon><v-icon>mdi-stop</v-icon>Ferma loop</v-btn>
				</div> -->
			</v-card-actions>
		</v-card>

		<div id="demo-controls">
			<audio id="audio" controls="controls">
				<source v-once v-if="this.audioSource" :src="this.audioSource" type="audio/mpeg">
				Your browser does not support the audio element.
			</audio>
		</div>
	</div>
</template>

<script>
	import Peaks from 'peaks.js';
	import Swal from 'sweetalert2';
	import { apiUrl } from "@/common/endpoint.const";
	import axios from '../../axios';
	import moment from 'moment';
	export default {
		name: "Waveform",
		props: [
			'file',
			'points',
			'loading',
			'getEventDetails',
			'relevance',
		],
		data() {
			return {
				audioEl: null,
				eventDetails: {
					event_name: "",
					time: "",
					description: ""
				},
				dateToShow: "",
				typingTimer: null,
				isUpdate: false,
				dialog: false,
				isPlaying: false,
				peaks: null,
				loopDuration: 5,
				loopWait: 2,
				updateData: "",
				isLooping: false,
				stopDuration: 5,
				isMute: false,
				audioSource: '',
				enableTranscriptinStop: false,
				volume: 80,
				token: sessionStorage.getItem('token')
			}
		},
		methods: {
			play() {
				this.peaks.player.play()
				this.isPlaying = true;
			},
			async updateAudioSource() {
				console.log("file: ", this.file)
				this.audioSource = apiUrl + '/downloadfile/' + this.file.name + "?token=" + this.token + '&deviceid=' + sessionStorage.getItem("deviceId");
			},
			stopAudioForSecond() {
				if (this.isPlaying && this.enableTranscriptinStop) {
					console.log("stopDuration: ", this.stopDuration);
					this.peaks.player.pause();
					clearTimeout(this.typingTimer);
					this.typingTimer = setTimeout(() => {
						console.log("stopDuration: ", this.stopDuration);
						this.peaks.player.play();
					}, this.stopDuration * 1000);
				}
			},
			pause() {
				this.peaks.player.pause();
				this.isPlaying = false;
			},
			back(seconds) {
				if (this.isPlaying)
					this.pause();
				this.peaks.player.seek(this.peaks.player.getCurrentTime() - seconds)
				this.isPlaying && this.play()
			},
			forward(seconds) {
				if (this.isPlaying)
					this.pause();
				this.peaks.player.seek(this.peaks.player.getCurrentTime() + seconds)
				this.isPlaying && this.play()
			},
			goToSecond(second) {
				console.log("second: ", second);
				// convert second to int
				second = parseInt(second);
				this.peaks.player.seek(second);
				!this.isPlaying && this.play();
			},
			startLoop() {
				const cur = this.peaks.player.getCurrentTime()
				this.peaks.segments.removeById('loop')
				this.peaks.segments.add({
					id: 'loop',
					startTime: cur,
					endTime: cur + (+this.loopDuration),
					labelText: 'LOOP'
				})
				this.peaks.player.playSegment(this.peaks.segments.getSegment('loop'), true)
				this.isPlaying = true;
				this.isLooping = true;
			},
			endLoop() {
				this.peaks.segments.removeById('loop')
				this.pause();
				this.isLooping = false;
			},
			zoomIn() {
				this.peaks.zoom.zoomIn()
			},
			zoomOut() {
				this.peaks.zoom.zoomOut()
			},
			volumeChange(volume) {
				this.isMute = volume === 0;
				this.audioEl.volume = volume / 100;
			},
			openDialog(event = null) {
				this.eventDetails = {
					event_name: "",
					time: "",
					description: ""
				}
				const eventTime = this.peaks.player.getCurrentTime();
				let dataRelevance = this.relevance.created_at;
				let dataRelevanceDate = new Date(dataRelevance);

				if (event == null) {
					this.isUpdate = false;
					this.eventDetails.time = eventTime;
					dataRelevanceDate.setSeconds(dataRelevanceDate.getSeconds() + eventTime);
				}
				else {
					this.eventDetails = event;
					this.isUpdate = true;
					dataRelevanceDate.setSeconds(dataRelevanceDate.getSeconds() + event.time);
				}
				dataRelevanceDate.setHours(dataRelevanceDate.getHours() - 2)
				let second = dataRelevanceDate.getSeconds();
				let minute = dataRelevanceDate.getMinutes();
				let hour = dataRelevanceDate.getHours();
				let day = dataRelevanceDate.getDate();
				let month = dataRelevanceDate.getMonth();
				let years = dataRelevanceDate.getFullYear();

				this.dateToShow = `${day}/${month}/${years} ${hour}:${minute}:${second}`;
				this.dialog = true;
			},
			addEvent() {
				const eventTime = this.peaks.player.getCurrentTime();
				this.peaks.points.add({
					time: eventTime,
					color: '#f00'
				})
				this.syncEvents();
				var saveOrUpdateRequest = {
					id: this.eventDetails.id,
					event_name: this.eventDetails.event_name,
					time: eventTime,
					description: this.eventDetails.description,
					relevanceId: this.relevance.id
				};
				axios.put(apiUrl + '/relevance/save-event',
					saveOrUpdateRequest, {
					headers: {
						'Authorization': this.token
					}
				}).then(res => {
					console.log(res)
					if (res?.data?.status) {
						Swal.fire({
							icon: 'success',
							title: !this.isUpdate ? 'Evento aggiunto con successo' : 'Evento modificato con successo',
							confirmButtonText: "Ok"
						})
						this.dialog = false;
						this.getEventDetails();
					}
					else {
						let message;
						if (res?.data?.message) {
							message = res.data.message;
						}
						else {
							message = !this.isUpdate ? 'Si è verificato un errore durante l\'aggiunta dell\'evento' : 'Si è verificato un errore durante la modifica dell\'evento';
						}
						Swal.fire({
							icon: 'error',
							title: 'Errore',
							text: message,
							confirmButtonText: "Ok"
						})
					}
				}).catch(err => {
					console.log("Err: ", err.message)
					Swal.fire({
						icon: 'error',
						title: 'Errore',
						text: err,
						confirmButtonText: "Ok"
					})
				})
			},
			syncEvents() {
				const points = this.peaks.points.getPoints();
				this.$emit('events', points[points.length - 1])
			},
			seek(time) {
				this.peaks.player.seek(time);
			}
		},
		watch: {
			events(newVal, oldVal) {
				console.log(newVal, oldVal)
			},
			async file(newValue, oldValue) {
				await this.updateAudioSource();
			},
		},
		async mounted() {
			this.updateAudioSource();
			const AudioContext = window.AudioContext || window.webkitAudioContext;
			this.audioEl = document.getElementById('audio');
			let dataRelevanceDate = new Date(this.relevance.created_at);

			console.log("dataRelevanceDate: ", dataRelevanceDate);
			let second = dataRelevanceDate.getSeconds();
			let minute = dataRelevanceDate.getMinutes();
			let hour = dataRelevanceDate.getHours();
			let day = dataRelevanceDate.getDate();
			let month = dataRelevanceDate.getMonth();
			let years = dataRelevanceDate.getFullYear();

			this.updateData = `${day}/${month + 1}/${years} ${hour}:${minute}:${second}`;
			const options = {
				containers: {
					zoomview: document.getElementById('zoomview-container'),
					overview: document.getElementById('overview-container')
				},
				mediaElement: this.audioEl,
				webAudio: {
					audioContext: new AudioContext,
					scale: 128,
					multiChannel: false
				},
				keyboard: true,

				pointMarkerColor: '#006eb0',
				showPlayheadTime: true,
				zoomLevels: [1024, 2048, 4096],
				logger: console.error.bind(console),
				nudgeIncrement: 3,
				overviewWaveformColor: '#0f0',
				zoomWaveformColor: '#0f0'
			}

			await Peaks.init(options, (err, peaksInstance) => {
				if (err) {
					console.error(err.message);
					return;
				}
				// get duration and convert in seconds
				this.peaks = peaksInstance;
				this.peaks.points.add(this.points.map(e => ({ time: +e.time })))
				this.peaks.on('player.timeupdate', (timeCursor) => {
					// let dataRelevance = this.relevance.created_at;
					let dataRelevanceDate = new Date(this.relevance.created_at);

					//console.log("timeCursor: ", timeCursor);
					dataRelevanceDate.setSeconds(dataRelevanceDate.getSeconds() + timeCursor);
					let second = dataRelevanceDate.getSeconds();
					let minute = dataRelevanceDate.getMinutes();
					let hour = dataRelevanceDate.getHours();
					let day = dataRelevanceDate.getDate();
					let month = dataRelevanceDate.getMonth();
					let years = dataRelevanceDate.getFullYear();

					this.updateData = `${day}/${month + 1}/${years} ${hour}:${minute}:${second}`;

					if (timeCursor >= this.peaks.player.getDuration()) {
						this.pause()
					}
				})
				this.$emit('set-duration')
				this.$emit('peaks-ready')
				console.log('Peaks instance ready');
			});
		}
	}
</script>

<style scoped>
	#zoomview-container {
		height: 100px;
	}

	#overview-container {
		height: 70px;
	}

	.container-controller {
		display: flex;
		justify-content: space-between;
	}

	.container-button__control {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		width: 100%;
	}

	.margin-left__5px {
		margin-left: 5px;
	}

	.v-text-field__input {
		margin-left: 16px;
		width: 0px !important;
	}

	#demo-controls {
		display: none;
	}

	.container-volume {
		display: flex !important;
		align-items: baseline;
		width: 35%;
	}
</style>
